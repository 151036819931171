import React, { useState } from "react";
import SelectDefaultDropdown from "./../../common/Dropdowns/SelectDefaultDropdown/SelectDefaultDropdown";
import { selectDropdown } from "../../../Globals/constent";
import { Link } from "react-router-dom";
import { AddToCartButton, Quantity } from "../Quantity/Quantity";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../features/cart/cartSlice";
import { LoadingComponent } from "../LoadingComponent/LoadingComponent";
import { WishListIcon } from "../../../features/wishList/WishListIcon";
import { isValidDiscount, rs } from "../../../utils/util";

const SearchProductList = ({ items, isLoading }) => {
    // const cartItems = useSelector((state) => state.cart);
    const [cartItems, setCartItems] = useState([]);
    const dispatch = useDispatch();
    const { total_count, result } = items;
    const addtocartHandler = (product) => {
        dispatch(addToCart(product));
    };
    return (
        <>
            <main className="container">
                <div className="col-xl-12 col-lg-12 col-md-12 mb-6 mb-md-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="row align-items-center mb-6">
                                <div className="col-lg-12 col-12">
                                    <div className="mb-4 mb-lg-0">
                                        <h3 className="mb-1 mt-4">Total Result Show : {total_count}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-cols-xl-5 row-cols-lg-3 g-6 search-list-filter">
                                {/* item */}
                                {isLoading ? (
                                    <LoadingComponent />
                                ) : (
                                    result?.map((d, i) => {
                                        const cartItem = cartItems.find((item) => item.id === d.id);
                                        return (
                                            <div
                                                className="col-6 col-md-3"
                                                key={i}
                                            >
                                                <div className="mb-6 search-product">
                                                    {/* card */}
                                                    <div className="card card-product card-pro-h mb-0 position-relative">
                                                        <div
                                                            className=" d-flex justify-content-between position-absolute mt-1 ms-1"
                                                            style={{
                                                                width: "100%",
                                                                padding:
                                                                    "0 10px",
                                                            }}
                                                        >
                                                            <span className="d-flex align-items-center position-relative" style={{ marginTop: '-4px', marginLeft: '-13px' }}>
                                                                {isValidDiscount(parseInt(d?.discount)) && <>
                                                                    <svg
                                                                        width="38"
                                                                        height="38"
                                                                        viewBox="0 0 48 48"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <g clip-path="url(#clip0_1948_532)">
                                                                            <path
                                                                                d="M14 10C14 8.89583 13.6094 7.95312 12.8281 7.17188C12.0469 6.39062 11.1042 6 10 6C8.89583 6 7.95312 6.39062 7.17188 7.17188C6.39062 7.95312 6 8.89583 6 10C6 11.1042 6.39062 12.0469 7.17188 12.8281C7.95312 13.6094 8.89583 14 10 14C11.1042 14 12.0469 13.6094 12.8281 12.8281C13.6094 12.0469 14 11.1042 14 10ZM47.3438 28C47.3438 29.1042 46.9583 30.0417 46.1875 30.8125L30.8438 46.1875C30.0312 46.9583 29.0833 47.3438 28 47.3438C26.8958 47.3438 25.9583 46.9583 25.1875 46.1875L2.84375 23.8125C2.05208 23.0417 1.38021 21.9896 0.828125 20.6562C0.276042 19.3229 0 18.1042 0 17V4C0 2.91667 0.395833 1.97917 1.1875 1.1875C1.97917 0.395833 2.91667 0 4 0H17C18.1042 0 19.3229 0.276042 20.6562 0.828125C21.9896 1.38021 23.0521 2.05208 23.8438 2.84375L46.1875 25.1562C46.9583 25.9688 47.3438 26.9167 47.3438 28Z"
                                                                                fill="#00713d"
                                                                            />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_1948_532">
                                                                                <rect
                                                                                    width="48"
                                                                                    height="48"
                                                                                    fill="white"
                                                                                />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                    <span className="discount-tag">
                                                                        {Math.round(parseInt(d?.discount)) + "%\n off"}
                                                                    </span>
                                                                </>}
                                                            </span>
                                                            <span className="text-danger">
                                                                <WishListIcon
                                                                    data={d}
                                                                    className="border-add btn-qlty-ct"
                                                                />
                                                            </span>
                                                        </div>
                                                        <div className="card-body card-body-pro-h py-0 mb-1">
                                                            {/* img */}
                                                            <Link
                                                                to={`product-detail?productId=${d?.id}`}
                                                            >
                                                                <img
                                                                    src={
                                                                        d?.product_image[0] ?? "assets/images/category/milk_dairy_cate.png"
                                                                    }
                                                                    alt={
                                                                        d?.name
                                                                    }
                                                                    className="img-fluid mb-0"
                                                                />
                                                            </Link>
                                                            {/* text */}

                                                            <div>
                                                                <h2 className="mt-3 fs-6 card-t-t">
                                                                    <Link
                                                                        to={
                                                                            d?.link
                                                                        }
                                                                        className="text-inherit"
                                                                    >
                                                                        {
                                                                            d?.product_name
                                                                        }
                                                                    </Link>
                                                                </h2>
                                                                <div className="text-small mb-0">
                                                                    <div className="text-decoration-none text-muted">
                                                                        <small>{d?.item_weight} gm</small>
                                                                    </div>
                                                                </div>
                                                                <div className="text-small mb-0">
                                                                    <div className="text-decoration-none text-muted">
                                                                        <small className="text-red">{d.stock < 1 && 'Sold Out'}</small>
                                                                    </div>
                                                                </div>
                                                                {/* <div>
                                                                    <span className="text-dark fs-5 fw-bold"> ₹ {d?.offer_price}</span>
                                                            <span className="text-decoration-line-through text-muted" style={{ marginRight: '20px' }}> ₹ {d?.price} </span>
                                                                    <button className="btn btn-light border-add">Add</button>
                                                                </div> */}
                                                                <div className="d-flex justify-content-between align-items-center mt-3 pro-bttn">
                                                                    <div>
                                                                        <span className="text-decoration-line-through text-muted">
                                                                            {isValidDiscount(parseInt(d.price) - parseInt(d.offer_price)) && rs + d.price}
                                                                        </span>
                                                                        <br />
                                                                        <span className="text-dark">
                                                                            {rs + d?.price}
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        <AddToCartButton data={d} />
                                                                    </div>
                                                                </div>

                                                                {/* <div className="text-warning">
                                                           
                                                            <small>
                                                                <i className="bi bi-star-fill" />
                                                                <i className="bi bi-star-fill" />
                                                                <i className="bi bi-star-fill" />
                                                                <i className="bi bi-star-fill" />
                                                                <i className="bi bi-star-fill" />
                                                            </small>
                                                            <span className="text-muted small">
                                                                {
                                                                    d?.new_price
                                                                        ?.rating
                                                                        ?.average
                                                                }
                                                            </span>
                                                        </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default SearchProductList;

import React from 'react'
import { Link } from 'react-router-dom'
import MySliders from '../../../../components/common/SlickSlider/MySliders'
import { isNullOrEmpty, rs } from '../../../../utils/util'
import { WishListIcon } from '../../../../features/wishList/WishListIcon'
import { AddToCartButton } from '../../../../components/common/Quantity/Quantity'
import RenderProductCard from './RenderProductCard'

const sliderOnject = {
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
}

const RelatedItems = ({ items, onClick }) => {
    return (
        <>
            {/* section */}
            <section className="my-lg-8 mb-14 releted-product">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-12">
                            {/* heading */}
                            <h3>Related Items</h3>
                        </div>
                    </div>
                    {/* row */}
                    <MySliders sliderOnject={sliderOnject}>
                        {
                            items?.map((d, i) => (
                                <div className="col-md-2 rendor-product-card" key={i}>
                                    <RenderProductCard onClick={() => onClick(d)} d={d} />
                                    
                                </div>
                            ))
                        }
                    </MySliders>
                </div>
            </section>
        </>
    )
}

export default RelatedItems
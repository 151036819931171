import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import routes from "./config/routeConfig";
import Layout from "./components/layout/Layout";
import auth from "./config/auth";
import ScrollToTop from "./components/common/ScrollToTop/ScrollToTop";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { useLocalStorage } from "./Globals/helper";
import { getCart } from "./store/reducers/getTotalCount";
import { getWishListData } from "./features/wishList/getWishList";
import { isNullOrEmpty, log } from "./utils/util";

function App() {
  const dispatch=useDispatch()
  const [token] = useLocalStorage("token");
  const { customer_id } = token || {};
  useEffect(()=>{
    if(!isNullOrEmpty(customer_id)){
    dispatch(getCart(customer_id))
    dispatch(getWishListData(customer_id))}
  },[])
  return (
    <BrowserRouter>
      <ToastContainer />
      <ScrollToTop />
      <Suspense fallback={<h1>Loading...</h1>}>
        <Routes>
          <Route path="/" element={<Layout />}>
            {
              routes?.map((route, i) => (
                <Route
                  key={i}
                  path={route?.path}
                  element={
                    route?.protected && !auth() ? <Navigate to="/" /> : route?.element
                  }
                />
              ))
            }
          </Route>
          <Route path="*" element={<h1>Page not found!</h1>} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;

import { Link } from "react-router-dom";
import BackButton from "../../../../components/common/BackButton/BackButton";

const SupportDetails = ({ data, onClose }) => {
  return (
    <div className="container">
      <div className="row mb-8 mt-11">
        <div className="col-md-12 mb-6">
          {/* <BackButton onClick={onClose} title="Back" /> */}
          <Link onClick={onClose}><svg width={18} height={18} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 12C0 18.6271 5.37288 24 12 24C18.6271 24 24 18.6271 24 12C24 5.37288 18.6271 0 12 0C5.37288 0 0 5.37288 0 12ZM12.9231 7.37942L9.255 11.0769H17.8846V12.9231H9.255L12.9231 16.6206L11.6135 17.921L5.73808 12L11.6135 6.07904L12.9231 7.37942Z" fill="#00713D" />
          </svg> Back</Link>
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  {data.name}
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  {data.answer.map((answer) => (
                    <div className="accordion" id={`accordionExample-${answer.id}`} key={answer.id}>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id={`heading-${answer.id}`}>
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse-${answer.id}`}
                            aria-expanded="false"
                            aria-controls={`collapse-${answer.id}`}
                          >
                            {answer.name}
                          </button>
                        </h2>
                        <div
                          id={`collapse-${answer.id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`heading-${answer.id}`}
                          data-bs-parent={`#accordionExample-${answer.id}`}
                        >
                          <div className="accordion-body">
                            <p>{answer.description}</p> 
                            {answer.Steps && answer.Steps.map((step, index) => (
                              <div key={index}>
                                <h6>Step {index + 1}: {step.name}</h6> 
                                <p>{step.description}</p> 
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {console.log('data =================', data)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportDetails;

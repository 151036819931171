import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiUrl, getRequest } from '../../config/apiConfig';
import { isNullOrEmpty, log } from '../../utils/util';
export const getWishListData = createAsyncThunk("wishList/getWishList", async (customer_id) => {
    if(isNullOrEmpty(customer_id)) return []
    log(customer_id,'ccid')
    let config = {url: `${ApiUrl?.getWishListItemsId}/${customer_id}`};
    const response = await getRequest(config)
    return response.data
})
const initialState = [];

const getWishList = createSlice({
    name: "wishList",
    initialState,
    reducers: {
        setWishList: (state, action) => {
            return state = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getWishListData.fulfilled, (state, action) => {
          return state=action.payload
        })
      },
})

export const { setWishList } = getWishList.actions;
export default getWishList.reducer;

import React, { useState } from "react";
// import { Modal, Button } from "react-bootstrap";
const ConfirmationModal = ({ title, message, setVisible, yes}) => {

           const modalStyles = {
            container: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
                backgroundColor: 'rgba(0, 0, 0, 0.4)', // Background dim effect
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 100
            },
            content: {
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                width: '280px',
                padding: '3px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
            },
            productTitle: {
                backgroundColor: '#116c3d', // Green
                color: 'white',
                fontWeight: 'bold',
                padding: '12px',
                borderRadius: '5px 5px 0 0',
                textTransform: 'uppercase',
                fontSize: '12px',
                letterSpacing: '0.5px',
            },
            body: {
                margin: '20px 0',
                fontSize: '14px',
                color: '#333333',
                lineHeight: 1.4,
            },
            actions: {
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '15px',
            },
            button: {
                padding: '8px 25px',
                border: 'none',
                borderRadius: '5px',
                fontSize: '14px',
                cursor: 'pointer',
                width: '40%',
            },
            buttonNo: {
                backgroundColor: '#177a3c', // Green for "No"
                color: 'white',
            },
            buttonYes: {
                backgroundColor: '#e74c3c', // Red for "Yes"
                color: 'white',
            },
        };

        return (
            <div style={modalStyles.container}>
                <div style={modalStyles.content}>
                    <div style={modalStyles.productTitle}>
                        {title}
                    </div>
                    <div style={modalStyles.body}>
                        <p>{message}</p>
                    </div>
                    <div style={modalStyles.actions}>
                        <button style={{ ...modalStyles.button, ...modalStyles.buttonNo }} onClick={() => setVisible(false)}>No</button>
                        <button style={{ ...modalStyles.button, ...modalStyles.buttonYes }} onClick={yes}>Yes</button>
                    </div>
                </div>
            </div>
        );
    };

    export default ConfirmationModal;
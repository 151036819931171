import React, { useEffect, useState } from 'react'
import { saveAddressButtonArray } from '../../Globals/constent';
import { APIRequest, ApiUrl } from '../../config/apiConfig';
import { toast } from 'react-toastify';
import CurrentLocation from '../../components/CurrentLocation/CurrentLocation';

const CartEditModal = ({ updateAddressById }) => {
    const [selectLableResult, setSelectLableResult] = useState("Home")
    const [editForm, setEditForm] = useState({
        customer_id: updateAddressById?.customer_id || "",
        id: updateAddressById?.id || "",
        receiver_name: updateAddressById?.receiver_name || "",
        house_floor_no: updateAddressById?.house_floor_no || "",
        landmark: updateAddressById?.landmark || "",
        alternate_mobile_number: updateAddressById?.alternate_mobile_number || "",
        lable: ""
    });

    useEffect(() => {
        setEditForm({
            customer_id: updateAddressById?.customer_id || "",
            id: updateAddressById?.id || "",
            receiver_name: updateAddressById?.receiver_name || "",
            house_floor_no: updateAddressById?.house_floor_no || "",
            landmark: updateAddressById?.landmark || "",
            alternate_mobile_number: updateAddressById?.alternate_mobile_number || "",
            lable: ""
        });
    }, [updateAddressById]);

    const changeInputValueHandler = (e) => {
        const { name, value } = e.target;
        setEditForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const selectLableHandler = (label) => {
        setSelectLableResult(label)
        let updated = { ...editForm }
        updated.lable = label;
        setEditForm(updated)
    }

    const saveCustomerAddressHandler = () => {
        let config = {
            method: "POST",
            url: `${ApiUrl?.customerUpdateAddress}`,
            body: editForm
        };
        APIRequest(
            config,
            (res) => {
                if (res?.success) {
                    toast.success(res?.message)
                    localStorage.setItem("userAddress", JSON.stringify(res?.data[0]))
                    setTimeout(() => {
                        window.location.reload(true)
                    }, 1000)
                }
            },
            (err) => {
                console.log(err, "err ===============");
            }
        );
    };

    return (
        <>
            {/* CartEditModal  */}
            <div
                className="modal fade"
                id="CartEditModal"
                tabindex={-1}
                aria-labelledby="CartEditModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" style={{ maxWidth: '800px' }}>
                    <div className="modal-content modal-contentnewadd" style={{ width: '100%' }}>
                        <div className="map-section" >
                            <CurrentLocation />
                        </div>
                        <div className="modal-right01" style={{ width: '50%' }}>
                            <div
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                id="closeEditbuttonModal"
                            >
                                &times;
                            </div>
                            <div className="form-section">
                                <h4 className="text-green">
                                    Edit Address Details
                                </h4>
                                <input
                                    type="text"
                                    name='house_floor_no'
                                    placeholder="House & floor no"
                                    value={editForm?.house_floor_no}
                                    onChange={changeInputValueHandler}
                                />
                                <input
                                    type="text"
                                    placeholder="Landmark"
                                    name='landmark'
                                    value={editForm?.landmark}
                                    onChange={changeInputValueHandler}
                                />
                                <input
                                    type="tel"
                                    placeholder="Alternate mobile no (optional)"
                                    name='alternate_mobile_number'
                                    value={editForm?.alternate_mobile_number}
                                    onChange={changeInputValueHandler}
                                />
                                <input
                                    type="text"
                                    placeholder="Receiver name"
                                    name='receiver_name'
                                    value={editForm?.receiver_name}
                                    onChange={changeInputValueHandler}
                                />

                                <ul className="nav nav-pills" role="tablist">
                                    {
                                        saveAddressButtonArray?.map((d, i) => (
                                            <li className="nav-item" style={{ marginRight: '3px' }}>
                                                <button
                                                    className={`nav-link ${selectLableResult === d?.label ? 'active' : ''}`}
                                                    data-bs-toggle="pill"
                                                    style={{ padding: '4px 10px' }}
                                                    onClick={() => selectLableHandler(d?.label)}
                                                >
                                                    {d?.icon}
                                                    {d?.label}
                                                </button>
                                            </li>
                                        ))
                                    }
                                </ul>
                                <button className="save-btn" onClick={saveCustomerAddressHandler}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CartEditModal

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subCategory: '',
    category: '',
    brandName: '',
    Status: ''
}

const productSlice = createSlice({
    name: 'productlist',
    initialState,
    reducers: {
        setCategory: (state, action) => {
            state.category = action.payload
        },
        setSubCategoryChoose: (state, action) => {
            state.subCategory = action.payload
        }
    }
})

export const { setCategory, setSubCategoryChoose } = productSlice.actions;
export default productSlice.reducer;

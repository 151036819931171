import React from "react"

export const LoadingComponent = () => {
    return (
        <div className="loaderNamerClass-ct">
            <img src="assets/images/loader/loader.gif" className="img-fluid" alt="loading" />
        </div>
    )
}

export const LoadingComponentScreen = () => {
    return (
        <div className="loaderNamerClass-ct" style={{position: 'fixed', top: '0', width: '100wv', height: '100vh'}}>
            <img src="assets/images/loader/loader.gif" className="img-fluid" alt="loading" />
        </div>
    )
}

export const CartLoadingComponentScreen = () => {
    return (
        <div className="loaderNamerClass-ct" style={{width: '10px', height: '10px', backgroundColor: 'unset'}}>
            <img style={{width: '20%', height: '20%', borderRadius: '50%'}} src="assets/images/loader/cart-button-spiner.gif" className="img-fluid" alt="loading" />
        </div>
    )
}
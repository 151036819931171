import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ChangeAddressModal from './ChangeAddressModal';
import CartEditModal from './CartEditModal';
import CartdeleteModal from './CartdeleteModal';
import { APIRequest, ApiUrl, getRequest } from '../../config/apiConfig';
import { Link, useNavigate } from 'react-router-dom';
import { getCart } from '../../store/reducers/getTotalCount';
import { useLocalStorage } from '../../Globals/helper';
import { setOrderSummary } from '../../store/Slices/orderSummarySlice';
import { isValidDiscount, log, logError, rs } from '../../utils/util';
import { AddToCartButton } from '../../components/common/Quantity/Quantity';
import RelatedItems from '../Product/ProductDetail/component/RelatedItems';

const Cart = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const store = useSelector(s => s.store)
    const cartData = useSelector((state) => state.cartCount)
    const orderSummary = useSelector((state) => state.orderSummary)
    const [relatedProducts, setProduct] = useState([])
    const isLogin = localStorage?.getItem("token");
    const [token] = useLocalStorage("token");
    const { customer_id, defaultStore } = token || {};
    const [updateAddressById, setUpdateAddressById] = useState({})
    const [deleteAddressById, setDeleteAddressById] = useState({})
    const [customerDefaultAddress, setCustomerDefaultAddress] = useState({});
    const selectAddressUserResult = JSON.parse(localStorage.getItem("userAddress"));

    useEffect(() => {
        const result = cartData?.data && cartData?.data?.reduce((acc, item) => {
            if (item.qty !== undefined && item.price !== undefined && item?.offer_price) {
                acc.TotalMRP += (item?.price * item?.qty || 0)
                acc.TotalOfferPrice += (item?.offer_price * item?.qty || 0)
                acc.Qty += (item?.qty || 0)
                acc.totalCountCart = cartData?.data?.length
            }
            return acc;
        }, { TotalMRP: 0, TotalOfferPrice: 0, Qty: 0, totalCountCart: 0 });
        dispatch(setOrderSummary(result))
    }, [cartData])
    useEffect(() => {
        dispatch(getCart(customer_id))
        getRandomProductBySubcat(cartData?.data)
        getCustomerDefaultAddressHandler();
    }, [])
    const getCustomerDefaultAddressHandler = () => {
        let config = {
            method: "get",
            url: `${ApiUrl?.customerDefaultAddressEndPoint}/${customer_id}?address=default`,
        };
        getRequest(config)
            .then(res => { if (res?.status === 200) setCustomerDefaultAddress(res?.result[0]) })
            .catch(console.log)
    };
    const getRandomProductBySubcat = (product) => {
        let config = {
            url: ApiUrl.getRandomProductBySubcat,
            method: "POST",
            body: JSON.stringify({
                storeId: store ? store?.store_id : defaultStore,
                subcategoryIds: product.map(item => item.subcategory_id),
                productIds: product.map(item => item.product_id),
            })
        };
        getRequest(config)
            .then(data => setProduct(data.result))
            .catch(logError);
    };
    return (
        <>
            <section className="mb-lg-8 mb-3 mt-5">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-12">
                            <div>
                                <div className="mb-3 me-4">
                                    {/* text */}
                                    <h4 className="text-green fw-bold mb-0">
                                        {cartData?.data?.length ?? 0} Items in Cart
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* row */}
                        <div className="cart-container">
                            <div className="product-list">
                                {cartData?.data?.map((d, i) => {
                                    return (
                                        <div className="product-item" key={i}>
                                            <div className="product-item-img-bg">
                                                <img
                                                    style={{
                                                        maxWidth: "200px",
                                                    }}
                                                    src={d?.product_image[0]}
                                                    alt={d?.product_image[0]}
                                                />
                                            </div>
                                            <div className="product-details">
                                                <p>{d?.product_name}</p>
                                                <p>{d?.item_weight}gm</p>
                                                <p className='text-red'>{d.stock < 1 && 'Sold Out'}</p>
                                                <p className="mt-6">
                                                    {isValidDiscount(parseInt(d.price) - parseInt(d.offer_price)) && <span className="original-price">
                                                        {rs + d?.price}{" "}
                                                    </span>}
                                                    <span className="discounted-price">
                                                        {rs + d?.offer_price}
                                                    </span>
                                                </p>
                                            </div>

                                            <div>
                                                <div>
                                                    <AddToCartButton data={d} className={' btn-qlty-ct'} />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}

                                <Link to="/">
                                    <h6 className="text-end">
                                        Continuous Shopping
                                    </h6>
                                </Link>
                                <RelatedItems items={relatedProducts} onClick={(d) => log(d)} />
                            </div>
                            <div className="order-summary">
                                <h4 className="text-white">Order Summary</h4>
                                <p>
                                    Total MRP: <span>₹ {orderSummary?.TotalMRP} </span>
                                </p>
                                <p>
                                    Total Offer Price: <span>₹ {orderSummary?.TotalOfferPrice}</span>
                                </p>
                                <p>
                                    Shipping Charge:{" "}
                                    <span className="text-warning">Free</span>
                                </p>
                                <p>
                                    Coupon Applied: <span>₹0.00</span>
                                </p>
                                <p>
                                    TOTAL: <span>₹ {orderSummary?.TotalOfferPrice}</span>
                                </p>
                                <p>
                                    Estimated Delivery: <span>10 minutes</span>
                                </p>
                                <hr />
                                <br />
                                <br />
                                <input type="text" placeholder="Coupon Code" />
                                <button style={{ marginTop: 10 }}>
                                    You save Rs. {cartData?.deliveryCharge} on this order including
                                    delivery charge
                                </button>
                                {/* <p class="savings">You save ₹6.60 on this order including delivery charge</p> */}
                                <div
                                    className="delievery"
                                    style={{ marginTop: 10 }}
                                >
                                    <p className="color_green fw-bold text-black">
                                        Delivery Address:{" "}
                                        <a
                                            href="#!"
                                            className="text-muted"
                                            data-bs-toggle="modal"
                                            data-bs-target="#ChangeAddressModal"
                                        >
                                            <span className="text-black">
                                                Change
                                            </span>
                                        </a>
                                    </p>
                                    <p className="text-black">
                                        {
                                            selectAddressUserResult ? (
                                                <>
                                                    {selectAddressUserResult?.house_floor_no} {selectAddressUserResult?.landmark}
                                                    <br />
                                                    {selectAddressUserResult?.address}
                                                </>
                                            ) : (
                                                <>
                                                    {customerDefaultAddress?.house_floor_no} {customerDefaultAddress?.landmark}
                                                    <br />
                                                    {customerDefaultAddress?.address}
                                                </>
                                            )
                                        }

                                    </p>
                                    <p className="text-black" />
                                </div>
                                {
                                    isLogin ? (
                                        <button
                                            className="checkout"
                                            style={{ marginTop: "18%" }}
                                            onClick={() => navigate("/checkout", { state: { customerDefaultAddress, selectAddressUserResult } })}
                                        >
                                            Proceed to Checkout
                                        </button>
                                    ) : (
                                        <button
                                            className="checkout"
                                            style={{ marginTop: "18%" }}
                                            onClick={() => navigate("/login", { state: { customerDefaultAddress, selectAddressUserResult } })}
                                        >
                                            {'Login to Proceed >'}
                                        </button>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ChangeAddressModal */}
            <ChangeAddressModal
                setUpdateAddressById={setUpdateAddressById}
                setDeleteAddressById={setDeleteAddressById}
            />
            {/* ChangeAddressModal */}
            <CartEditModal
                updateAddressById={updateAddressById}
            />
            {/* CartdeleteModal */}
            <CartdeleteModal
                deleteAddressById={deleteAddressById}
            />
        </>
    );
}

export default Cart
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APIRequest, ApiUrl } from "../../config/apiConfig";
import { useLocalStorage } from "../../Globals/helper";
import { getWishListData } from "./getWishList";

export const WishListIcon = ({ data, className }) => {
    const store=useSelector(s=>s.store)
    const [token] = useLocalStorage("token");
    const { defaultStore, customer_id } = token || {};
    const wishList = useSelector((state) => state.wishList);
    const dispatch = useDispatch();
    const isItemInWishlist = (list, item) => {
        let itemID = item.product_id ?? item.id
        if (list.filter(product => product.product_id == itemID).length > 0) {
            return true
        }
        return false
    }
    const addtowishHandler = (product) => {
        let config = {
            method: "POST",
            url: `${ApiUrl?.addtowish}`,
            body: {
                customer_id: customer_id,
                store_id:store? store?.store_id: defaultStore,
                order_details: [
                    {
                        product_id: product.id,
                        category_id: product.category_id,
                        subcategory_id: product.subcategory_id === undefined ? 1 : product.subcategory_id
                    }
                ]
            },
        };
        APIRequest(
            config,
            (res) => {
                if (res?.status === 200) {
                   dispatch(getWishListData(customer_id))
                }
            },
            (err) => {
                console.log(err);
            }
        );
    };
    const removeItem = (product) => {
        let pid = product.product_id ?? product.id
        let item = wishList.find(item => item.product_id == pid)
        let config = {
            method: "DELETE",
            url: `${ApiUrl?.deleteWishlistItems}/${item.wishlist_id}`,
        };
        APIRequest(
            config,
            (res) => {
                dispatch(getWishListData(customer_id))
            },
            (err) => {
                console.log('deleteWishlistItem');
            }
        );
    };

    return (
        <span
            className={`wishlist-icon wishlist-icon01 btn-qlty-ct-wish ${className}`}
            onClick={() =>
                isItemInWishlist(wishList, data)
                    ? removeItem(data)
                    : addtowishHandler(data)
            }
        >
            {isItemInWishlist(wishList, data) ? (
                <svg width="34" height="34" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.9999 42L23.1562 41.4375C19.1456 38.7591 14.0709 35.7319 10.3124 31.2825C6.3515 26.595 4.45119 21.7809 4.49994 16.5637C4.559 10.7381 9.23056 6 14.9137 6C19.424 6 22.4231 8.625 23.9999 10.5197C25.5768 8.625 28.5759 6 33.0862 6C38.7693 6 43.4409 10.7381 43.4999 16.5609C43.5524 21.7809 41.6521 26.5922 37.6874 31.2797C33.929 35.7319 28.8543 38.7591 24.8437 41.4375L23.9999 42Z" fill="#FF190B" />
                </svg>
            ) : (
                    <svg width="34" height="34" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00007 18.0606C6.05854 12.3568 10.5898 7.5 16.4137 7.5C19.9885 7.5 22.618 9.26949 24.2961 10.9355C24.7633 11.3994 25.1642 11.8622 25.5 12.2888C25.8357 11.8622 26.2367 11.3994 26.7039 10.9355C28.3819 9.26949 31.0115 7.5 34.5862 7.5C40.4102 7.5 44.9414 12.3568 44.9999 18.0606L44.9999 18.061C45.1108 29.1855 36.1722 36.8816 27.1875 42.9805C26.6898 43.3191 26.1019 43.5001 25.5 43.5001C24.8981 43.5001 24.3101 43.3191 23.8125 42.9805C14.8269 36.8817 5.88814 29.1855 6.00006 18.0608L6.00007 18.0606ZM26.8398 15.6745C26.585 16.1805 26.0668 16.5 25.5 16.5C24.9332 16.5 24.415 16.1806 24.1602 15.6746C24.1599 15.674 24.1593 15.6728 24.1584 15.671C24.1566 15.6674 24.1535 15.6616 24.1493 15.6536C24.147 15.6493 24.1445 15.6445 24.1415 15.639C24.1247 15.6074 24.0966 15.5559 24.0574 15.4874C23.9788 15.3502 23.8561 15.1458 23.6894 14.8966C23.3544 14.3959 22.8509 13.7283 22.1824 13.0645C20.8388 11.7305 18.9252 10.5 16.4137 10.5C12.3453 10.5 9.04283 13.9193 8.99991 18.091M26.8398 15.6745C26.8401 15.6739 26.8407 15.6727 26.8416 15.671C26.8444 15.6656 26.85 15.6548 26.8584 15.639C26.8753 15.6074 26.9033 15.5559 26.9426 15.4874C27.0211 15.3502 27.1438 15.1458 27.3105 14.8966C27.6456 14.3959 28.149 13.7283 28.8176 13.0645C30.1612 11.7305 32.0748 10.5 34.5862 10.5C38.6546 10.5 41.9571 13.9193 42.0001 18.0909C42.0935 27.4652 34.5649 34.347 25.5013 40.4992L25.5 40.5001L25.4986 40.4992C16.4342 34.347 8.90575 27.4654 8.99991 18.0913" fill="black" />
                    </svg>

            )}
        </span>
    );
};

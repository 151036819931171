import React from 'react'
import { Link } from 'react-router-dom'

const BackButton = ({ path, title, state }) => {
    return (
        <div className="col-md-11">
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-4">
                <div>
                    <span className="nav-link-icon"><svg width={18} height={18} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 12C0 18.6271 5.37288 24 12 24C18.6271 24 24 18.6271 24 12C24 5.37288 18.6271 0 12 0C5.37288 0 0 5.37288 0 12ZM12.9231 7.37942L9.255 11.0769H17.8846V12.9231H9.255L12.9231 16.6206L11.6135 17.921L5.73808 12L11.6135 6.07904L12.9231 7.37942Z" fill="#00713D" />
                    </svg>
                    </span>
                    <Link to={path} state className="order-summery">{title}</Link>
                </div>
            </div>
        </div>
    )
}

export default BackButton
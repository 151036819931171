import React from 'react'

const WisheleteModal = () => {
    return (
        <>
            {/* CartdeleteModal */}
            <div
                className="modal fade"
                id="WisheleteModal"
                tabIndex={-1}
                aria-labelledby="WisheleteModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content-delete">
                        {/* <div class="close" data-bs-dismiss="modal" aria-label="Close">&times;</div> */}
                        <p>Are you sure want to delete this delivery address</p>
                        <div className="modal-buttons">
                            <button className="no-btn">No</button>
                            <button className="yes-btn">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WisheleteModal;

import React, { useEffect, useState } from "react";
import { saveAddressButtonArray } from "../../Globals/constent";
import { useLocalStorage } from "../../Globals/helper";
import CurrentLocation from "../../components/CurrentLocation/CurrentLocation";
import { toast } from "react-toastify";
import { APIRequest, ApiUrl } from "../../config/apiConfig";
import { useSelector } from "react-redux";

const AddNewAddressFormModal = () => {
    const store=useSelector(s=>s.store)
    const [token] = useLocalStorage("token");
    const { defaultStore, customer_id } = token || {};
    const [userUpdatedAddress, setUserUpdatedAddress] = useState({})
    const [userSaveNewdAddress, setUserSaveNewdAddress] = useState({})
    const [selectLableResult, setSelectLableResult] = useState("Home")
    const [addForm, setAddForm] = useState({
        "receiver_name": "",
        "house_floor_no": "",
        "landmark": "",
        "alternate_mobile_number": "",
        "label": "",
    });

    const changeInputValueHandler = (e) => {
        const { name, value } = e.target;
        setAddForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const selectLableHandler = (label) => {
        setSelectLableResult(label)
        let updated = { ...addForm }
        updated.label = label;
        setAddForm(updated)
    }

    const saveNewAddressHandler = () => {
        if (addForm.house_floor_no === "" || addForm.landmark === "" || addForm.receiver_name === "") {
            toast.error("Please enter complete address");
            return;
        }
        const mobileNumberPattern = /^\d{10}$/;
        if (!mobileNumberPattern.test(addForm.alternate_mobile_number)) {
            toast.error("Please enter a valid 10-digit alternate mobile number");
            return;
        }
        let config = {
            method: "POST",
            url: `${ApiUrl?.customersaveAddress}`,
            body: {
                "customer_id": customer_id,
                "district": userSaveNewdAddress?.district,
                "pincode": userSaveNewdAddress?.pincode ? userSaveNewdAddress?.pincode : null,
                "receiver_name": addForm?.receiver_name,
                "area": userSaveNewdAddress?.area,
                "society": userSaveNewdAddress?.area,
                "state": userSaveNewdAddress?.state,
                "full_address": userUpdatedAddress?.formatted_address,
                "house_floor_no": addForm?.house_floor_no,
                "landmark": addForm?.landmark,
                "alternate_mobile_number": addForm?.alternate_mobile_number,
                "latitude": userUpdatedAddress?.geometry?.location.lat,
                "longitude": userUpdatedAddress?.geometry?.location.lng,
                "label": addForm?.label ? addForm?.label : 'Home',
                "store_id": store && store.length > 0 ? store[0]?.store_id : defaultStore
            }
        };
        APIRequest(
            config,
            (res) => {
                if (res?.success) {
                    toast.success(res?.message)
                    // closeEditbuttonModal.click();
                    setTimeout(() => {
                        window.location.reload(true)
                    }, 1000)
                }
            },
            (err) => {
                // console.log(err?.response?.data, "err ===============");
                if (err?.response?.data?.status === "failed") {
                    const { message } = err?.response?.data;
                    toast.error(message)
                }
            }
        );
    }

    useEffect(() => {
        let filterAddressResult = {};
        userUpdatedAddress?.address_components?.forEach((item) => {
            item.types.forEach((item1) => {
                if (item1 === "locality") {
                    filterAddressResult.area = item.long_name
                } else if (item1 === "administrative_area_level_1") {
                    filterAddressResult.state = item.long_name
                } else if (item1 === "administrative_area_level_2") {
                    filterAddressResult.city = item.long_name
                } else if (item1 === "administrative_area_level_3") {
                    filterAddressResult.district = item.long_name
                } else if (item1 === "country") {
                    filterAddressResult.country = item.long_name
                } else if (item1 === "postal_code") {
                    filterAddressResult.pincode = item.long_name
                }
            })
        })
        setUserSaveNewdAddress(filterAddressResult)
    }, [userUpdatedAddress])

    return (
        <>
            {/* AddNewAddressFormModal  */}
            <div
                className="modal fade"
                id="AddNewAddressFormModal"
                tabindex={-1}
                aria-labelledby="addAddressModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" style={{ maxWidth: '800px' }}>
                    <div className="modal-content modal-contentnewadd" style={{ width: '100%' }}>
                        <div className="map-section" >
                            <CurrentLocation
                                setUserUpdatedAddress={setUserUpdatedAddress}
                            />
                        </div>
                        <div className="modal-right01" style={{ width: '50%' }}>
                            <div
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                id="closeAddbuttonModal"
                            >
                                &times;
                            </div>
                            <div className="form-section">
                                <h4 className="text-green">
                                    New Address Details
                                </h4>
                                <input
                                    type="text"
                                    name='house_floor_no'
                                    placeholder="House & floor no"
                                    value={addForm?.house_floor_no}
                                    onChange={changeInputValueHandler}
                                />
                                <input
                                    type="text"
                                    placeholder="Landmark"
                                    name='landmark'
                                    value={addForm?.landmark}
                                    onChange={changeInputValueHandler}
                                />
                                <input
                                    type="tel"
                                    pattern="[0-9]{10}"
                                    maxLength="10"
                                    placeholder="Alternate mobile no (optional)"
                                    name='alternate_mobile_number'
                                    value={addForm?.alternate_mobile_number}
                                    onChange={changeInputValueHandler}
                                />
                                {/* <input
                                    type="tel"
                                    placeholder="Pin Code"
                                    name='pincode'
                                    value={addForm?.pincode}
                                    onChange={changeInputValueHandler}
                                /> */}
                                <input
                                    type="text"
                                    placeholder="Receiver name"
                                    name='receiver_name'
                                    value={addForm?.receiver_name}
                                    onChange={changeInputValueHandler}
                                />

                                <ul className="nav nav-pills" role="tablist">
                                    {
                                        saveAddressButtonArray?.map((d, i) => (
                                            <li className="nav-item" style={{ marginRight: '3px' }}>
                                                <button
                                                    className={`nav-link ${selectLableResult === d?.label ? 'active' : ''}`}
                                                    data-bs-toggle="pill"
                                                    style={{ padding: '4px 10px' }}
                                                    onClick={() => selectLableHandler(d?.label)}
                                                >
                                                    {d?.icon}
                                                    {d?.label}
                                                </button>
                                            </li>
                                        ))
                                    }
                                </ul>
                                <button className="save-btn" onClick={saveNewAddressHandler}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddNewAddressFormModal;

import React, { useMemo, useState } from 'react'
import SearchProductList from '../SearchProductList/SearchProductList'
import { toast } from 'react-toastify';
import { ApiUrl, getRequest } from '../../../config/apiConfig';
import { useLocalStorage } from '../../../Globals/helper';
import { log } from '../../../utils/util';
import { useSelector } from 'react-redux';

const SearchInput = ({ searchQuery }) => {
    const store=useSelector(s=>s.store)
    const [token] = useLocalStorage("token");
    const { defaultStore } = token || {};
    const [isLoading, setIsLoading] = useState(false)
    const [globalCategoryResult, setGlobalCategoryResult] = useState([]);

    const getGlobalCategoryHandler = () => {
        setIsLoading(true)
        let config = {
            url: `${ApiUrl?.getbysearch}?store=${store? store?.store_id : defaultStore}&name=${searchQuery}`,
            method: "GET",
        }
        log('search url',config.url)
        getRequest(config)
            .then(res => {
                if (res?.status === 200) {
                    setGlobalCategoryResult(res)
                    setIsLoading(false)
                }
            }).catch(err => {
                toast.error(err?.message)
                setIsLoading(false)
            })
    }

    useMemo(() => {
        getGlobalCategoryHandler();
    }, [searchQuery,store])

    return (
        <>
            <div style={{ display: `${searchQuery?.length > 0 ? 'block' : 'none'}` }}>
                <div style={{ position: 'absolute', width: '100%', backgroundColor: '#fff', left: '0', top: '73px', display: 'flex', justifyContent: 'center' }}>
                    <SearchProductList
                        items={globalCategoryResult}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </>
    )
}

export default SearchInput
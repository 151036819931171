import React, { useState, useCallback, useRef } from 'react';
import { GoogleMap, useJsApiLoader, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import axios from 'axios';
import { useLocalStorage } from '../../Globals/helper';
import { googleAPI_Key } from '../../config/apiConfig';

const containerStyle = {
    width: '100%',
    height: '600px'
};

const searchBoxStyle = {
    boxSizing: 'border-box',
    border: '1px solid transparent',
    width: '340px',
    height: '40px',
    marginTop: '10px',
    padding: '0 12px',
    borderRadius: '3px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
    fontSize: '14px',
    outline: 'none',
    textOverflow: 'ellipsis',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: '10px',
    zIndex: '5'
};

function CurrentLocation({ setUserUpdatedAddress }) {
    const [geometry] = useLocalStorage("location");
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: googleAPI_Key,
        libraries: ['places'] // Add 'places' library for autocomplete
    });

    const [currentLocation, setCurrentLocation] = useState(null);
    const [searchedLocation, setSearchedLocation] = useState(null);
    const [address, setAddress] = useState('');
    const [map, setMap] = useState(null); // State for map instance
    const searchBoxRef = useRef(null);

    let { location } = geometry?.geometry || {};

    const getAddress = async (lat, lng) => {
        try {
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleAPI_Key}`);
            if (response.data.results.length > 0) {
                setAddress(response.data.results[0]?.formatted_address);
                setUserUpdatedAddress(response.data.results[0])
                localStorage.setItem("location", JSON.stringify(response.data.results[0]))
                // console.log(response.data.results[0], 'response.data.results[0]')
            } else {
                setAddress('Address not found');
            }
        } catch (error) {
            console.error('Error fetching address:', error);
            setAddress('Error fetching address');
        }
    };

    const onLoad = useCallback((map) => {
        setMap(map);
    }, []);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    const onPlacesChanged = useCallback(() => {
        const places = searchBoxRef.current.getPlaces();
        if (places?.length === 0) {
            return;
        }

        const place = places[0];
        if (place.geometry) {
            const location = place.geometry.location;
            const newLocation = { lat: location.lat(), lng: location.lng() };
            setSearchedLocation(newLocation);
            getAddress(newLocation?.lat, newLocation?.lng)
            setAddress(place.formatted_address);
            if (map) {
                map.panTo(newLocation); // Center the map on the new location
                map.setZoom(15); // Optional: Zoom in to the searched location
            }
        }

    }, [map]);


    const chooseCurrentAddress = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const newLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    setCurrentLocation(newLocation);
                    getAddress(newLocation?.lat, newLocation?.lng)
                    if (map) {
                        map.panTo(newLocation); // Center the map on the current location
                    }
                },
                (error) => {
                    console.error('Error getting location:', error);
                },
                { enableHighAccuracy: true, timeout: 10000 }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }

    // console.log(address_components, 'address_components')

    return isLoaded ? (
        <div>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={currentLocation ? currentLocation : location}
                zoom={15}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >
                <StandaloneSearchBox
                    onLoad={(ref) => searchBoxRef.current = ref}
                    onPlacesChanged={onPlacesChanged}
                >
                    <input
                        type="text"
                        value={address}
                        placeholder="Search for a location"
                        onChange={(e) => setAddress(e.target.value)}
                        style={searchBoxStyle}
                    />
                </StandaloneSearchBox>
                {currentLocation && <Marker position={currentLocation} />}
                {searchedLocation && <Marker position={searchedLocation} />}
            </GoogleMap>

            <button className="use-location-btn" onClick={chooseCurrentAddress}>
                Use Current Location
            </button>
        </div>
    ) : <></>;
}

export default React.memo(CurrentLocation);

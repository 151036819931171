import React, { useCallback, useEffect, useState } from 'react'
import { APIRequest, ApiUrl } from '../../../config/apiConfig';
import { toast } from 'react-toastify';
import { getCart } from '../../../store/reducers/getTotalCount';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'

const LoginForm = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [componentId, setComponentId] = useState(1);
    const [mobileNo, setMobileNo] = useState('');
    const [time, setTime] = useState(0)
    const [otp, setOtp] = useState({
        num1: '',
        num2: '',
        num3: '',
        num4: '',
        num5: '',
        num6: '',
    })

    const getOtpValue = (e) => {
        setOtp({ ...otp, [e.target.name]: e.target.value })
    }

    const timemerhander = useCallback(() => {
        const timerId = setTimeout(() => {
            window.location.reload();
        }, 1000);
        return () => clearTimeout(timerId)
    })

    const loginHandler = () => {
        setIsLoading(true)
        let config = {
            url: ApiUrl?.login,
            method: "post",
            body: {
                "mobile": mobileNo,
                "hash_key": "12345678"
            }
        }
        APIRequest(
            config,
            res => {
                if (res?.status === 200) {
                    toast.success(res?.message)
                    setComponentId(2);
                    setTime(30)
                } else if (res?.status === "failed") {
                    toast.error(res?.validation_error?.mobile[0])
                }
            },
            err => {
                setIsLoading(false)
                if (err?.error) {
                    toast.error(err?.message)
                }
            }
        )
    }

    const otpVerifyHandler = () => {
        setIsLoading(true)
        let config = {
            url: ApiUrl?.otpverify,
            method: "post",
            body: {
                "mobile": mobileNo,
                "otp": otp.num1 + otp.num2 + otp.num3 + otp.num4 + otp.num5 + otp.num6,
            }
        }
        APIRequest(
            config,
            res => {
                if (!res?.error) {
                    if (res?.status) {
                        // setMobileNo('')
                        // setComponentId(3)
                        toast.success(res?.message)
                        getprofile(res);    
                        // setMobileNo(res?.mobileNo);
                        
                        timemerhander();
                    } else {
                        toast.error(res?.message)
                    }
                }
            },
            err => {
                setIsLoading(false)
                if (err?.error) {
                    toast.error(err?.message)
                }
            }
        )
    }


    const handleMobileNoChange = (e) => {
        setMobileNo(e.target.value.replace(/[^0-9]/g, ''));
    };

    const handleOtpInputFocus = () => {
        const inputs = document.querySelectorAll('.input');
        inputs.forEach((input, index) => {
            input.addEventListener('input', () => {
                if (input.value.length > 0 && index < inputs.length - 1) {
                    inputs[index + 1].focus();
                }
            });
            input.addEventListener('keydown', (e) => {
                if (e.key === 'Backspace' && input.value.length === 0 && index > 0) {
                    inputs[index - 1].focus();
                }
            });
        });
    };


    const inputs = document.querySelectorAll('.input');

    inputs.forEach((input, index) => {
        input.addEventListener('input', () => {
            if (input.value.length > 0 && index < inputs.length - 1) {
                inputs[index + 1].focus();
            }
        });

        input.addEventListener('keydown', (e) => {
            if (e.key === 'Backspace' && input.value.length === 0 && index > 0) {
                inputs[index - 1].focus();
            }
        });
    });

    let closeBtn = document.getElementById("close");
    closeBtn?.addEventListener('click', function () {
        if (componentId === 3) {
            setComponentId(1)
        }
    })

    useEffect(() => {
        if (componentId === 2) {
            if (time > 0) {
                let timerId = setTimeout(() => {
                    setTime((prev) => prev - 1);
                }, 1000);
                return () => clearTimeout(timerId);
            }
        }
    }, [time, componentId]);


// get profile
const getprofile = (profile) => {
    setIsLoading(true);
console.log(profile,'pppp')
    let config = {
        url: ApiUrl?.getprofile + profile.customer_id,
        method: "get",
        token: profile.token,
    };
    APIRequest(
        config,
        (res) => {
            if (!res?.error) {
                if (res?.status) {
                    setMobileNo("");
                    setComponentId(3);
                    
                    toast.success(res?.message);
                    let obj = res.result[0];
                    obj.token=profile.token
                    obj.customer_id = profile.customer_id;
                    // setMobileNo(res?.mobileNo);
                    localStorage.setItem("token", JSON.stringify(obj));
                    timemerhander();
                } else {
                    toast.error(res?.message);
                }
            }
        },
        (err) => {
            setIsLoading(false);
            if (err?.error) {
                toast.error(err?.message);
            }
        }
    );
};




    return (
        <>
            {/* Modal */}
            <div className="modal fade" id="userModal" tabIndex={-1} aria-labelledby="userModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-left">
                            <img className="img-fluid" src="assets/images/logo/logo.png" alt="Big Bonus Logo" />
                        </div>
                        <div className="modal-right">
                            <div className="close" data-bs-dismiss="modal" aria-label="Close" id="close">×</div>
                            {
                                componentId === 1 && (
                                    <>
                                        <h2>Login or<br />Create new account</h2>
                                        <div className="mb-5">
                                            <input type="tel" name="phone"  
                                                className="form-control mt-5 mb-5"
                                                id="mobile"
                                                pattern="[0-9]{10}" 
                                                placeholder="Enter your 10 digit mobile no" maxlength="10"
                                                onChange={handleMobileNoChange}
                                                // onChange={(e) => setMobileNo(e.target.value)} required 
                                            />
                                        </div>
                                        <button type="button" className="btn mt-3" onClick={loginHandler}>Send OTP</button>
                                        <p className="text-modal">By Send OTP you agree to our <Link to="/terms-conditions" style={{ color: 'white' }}>Terms &amp; Conditions</Link></p>
                                    </>
                                )
                            }

                            {
                                componentId === 2 && (
                                    <>
                                        <h4>Enter OTP<br />sent on mobile - {mobileNo}</h4>
                                        <div id="inputs" className="inputs">
                                            <input className="input" type="text" name='num1' onChange={getOtpValue} inputMode="numeric" maxLength={1} />
                                            <input className="input" type="text" name='num2' onChange={getOtpValue} inputMode="numeric" maxLength={1} />
                                            <input className="input" type="text" name='num3' onChange={getOtpValue} inputMode="numeric" maxLength={1} />
                                            <input className="input" type="text" name='num4' onChange={getOtpValue} inputMode="numeric" maxLength={1} />
                                            <input className="input" type="text" name='num5' onChange={getOtpValue} inputMode="numeric" maxLength={1} />
                                            <input className="input" type="text" name='num6' onChange={getOtpValue} inputMode="numeric" maxLength={1} />
                                        </div>
                                        <button type="button" className="btn mt-3" onClick={(otpVerifyHandler)}>Submit</button>
                                        <p onClick={loginHandler} style={{ color: '#FFC530', cursor: 'pointer', fontSize: '0.8rem', lineHeight: '1.7', textAlign: 'right' }}>OTP Time : {time} {time === 0 && 'Re-Send OTP'}</p>
                                        {/* <p style={{ color: '#FFC530', fontSize: '0.8rem', lineHeight: '1.7' }}>Some thing wrong please Re-enter OTP</p> */}
                                        <p className="text-modal">By Send OTP you agree to our <Link to="/terms-conditions" target="_blank" style={{ color: 'white' }}>Terms &amp;
                                            Conditions</Link></p>
                                    </>
                                )
                            }

                            {
                                componentId === 3 && (
                                    <div className="otp-verifeid">
                                        <h5 className="otp-verifeid-h2" style={{ color: '#fff' }}>
                                            Hey Welcome ! Enjoy
                                            <br /> shopping with Bigbonus{" "}
                                        </h5>
                                        <img
                                            className="img-fluid"
                                            src="assets/images/verified.png"
                                            alt="Big Bonus Logo"
                                        />
                                    </div>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginForm
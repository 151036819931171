import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useJsApiLoader, StandaloneSearchBox } from '@react-google-maps/api';
import { APIRequest, ApiUrl, getAutoAddress, googleAPI_Key, getRequest } from '../../config/apiConfig';
import { toast } from 'react-toastify';
import { saveLocation } from './saveLocationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocalStorage } from '../../Globals/helper';
import { log, isListNullOrEmpty, logError } from '../../utils/util';
import { setStore } from '../../store/Slices/storeSlice';
const LocationDetection = () => {
    const dispatch = useDispatch();
    const [token] = useLocalStorage("token");
    const { customer_id } = token || {};
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: googleAPI_Key,
        libraries: ['places']
    });
    const store = useSelector(s => s.store)
    const [address, setAddress] = useState('');
    const searchBoxRef = useRef(null);
    const searchAndSaveLocationHandler = (address) => {
        let closebtn = document.getElementById("closeBtn");
        let config = {
            method: 'post',
            url: `${ApiUrl?.searchAndSaveLocation}`,
            body: {
                "customer_id": customer_id,
                "district": address?.address2,
                "pincode": address.pincode,
                "area": address.address,
                "society": address?.address1,
                "state": address?.addressDetails.state,
                "lat": address.lat,
                "long": address.long
            }
        };

        APIRequest(
            config,
            res => {
                console.log('address with store', res)
                if (res?.status === 200) {
                    toast.success(res?.message)
                    getDeliveryTime(res?.delivery_distance, res?.data[0].store_id)
                    closebtn.click();
                    setAddress('');
                }
            },
            err => {
                // closebtn.click();
                if (err?.response?.data?.status === 404) {
                    toast.error(err?.response?.data?.message)
                }
            }
        )
    }
    const getDeliveryTime = (distance, store_id) => {
        let config = {
            method: 'post',
            url: `${ApiUrl?.getDeliveryTime}`,
            body: JSON.stringify({ distance })
        };
        getRequest(config)
            .then(data => {
                log(`get time store:${store_id}`, `\ndistance :${distance}`)
                if (!isListNullOrEmpty(data?.result)) {
                    let store = data?.result[0]
                    store.store_id = store_id;
                    store.distance = distance;
                    dispatch(setStore(store))
                }
            })
            .catch(error => {
                // dispatch({ type: 'STORE', payload: {} });
                logError(error, 'thunk56');
            });
    }
    const getAddress = async (lat, lng) => {
        try {
            const address = await getAutoAddress(lat, lng)
            if (address) {
                searchAndSaveLocationHandler(address)
                localStorage.setItem("location", JSON.stringify(address))
                dispatch(saveLocation(address))
            } else {
                setAddress('Address not found');
            }
        } catch (error) {
            console.error('Error fetching address:', error);
            setAddress('Error fetching address');
        }
    };

    const locationDetectionHandler = () => {
        const closebtn = document.getElementById("close");
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                getAddress(position?.coords?.latitude, position?.coords?.longitude)
                closebtn.click();
            }, (error) => {
                console.log(error, "error")
            })
        } else {
            console.log("Geolocation not supported")
        }
    }

    const onPlacesChanged = useCallback(() => {
        const places = searchBoxRef.current.getPlaces();
        if (places?.length === 0) {
            return;
        }
        const place = places[0];
        if (place.geometry) {
            const location = place.geometry.location;
            setAddress(place.formatted_address);
            getAddress(location.lat(), location.lng())
            localStorage.setItem("location", JSON.stringify(place))
            dispatch(saveLocation({ formatted_address: place.formatted_address }))
        }
    }, []);


    useEffect(() => {
        const timerId = setTimeout(() => {
            let checkLocationKey = JSON.parse(localStorage.getItem("location"))
            let modulButton = document.getElementById("model01");
            if (!checkLocationKey) {
                modulButton.click();
            }
        }, 1000);

        return () => clearTimeout(timerId);

    }, [])

    return isLoaded ? (
        <div className="modal fade" id="locationModal" tabIndex={-1} aria-labelledby="locationModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-sm">
                <div className="modal-content">
                    <div className="modal-body">
                        <div
                            className="close"
                            id="closeBtn"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            ×
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4>Change your location</h4>
                                </div>
                            </div>
                            <div className="location-container">
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    onClick={locationDetectionHandler}>
                                    <svg className='me-2' width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 2.069V0H9V2.069C7.2403 2.29368 5.60497 3.09617 4.35057 4.35057C3.09617 5.60497 2.29368 7.2403 2.069 9H0V11H2.069C2.29335 12.7598 3.09574 14.3953 4.3502 15.6498C5.60466 16.9043 7.24017 17.7066 9 17.931V20H11V17.931C12.7599 17.7068 14.3955 16.9045 15.65 15.65C16.9045 14.3955 17.7068 12.7599 17.931 11H20V9H17.931C17.7066 7.24017 16.9043 5.60466 15.6498 4.3502C14.3953 3.09574 12.7598 2.29335 11 2.069ZM10 16C6.691 16 4 13.309 4 10C4 6.691 6.691 4 10 4C13.309 4 16 6.691 16 10C16 13.309 13.309 16 10 16Z" fill="white" />
                                    </svg>
                                    Current location
                                </button>
                                <div className="w-100">
                                    <StandaloneSearchBox
                                        onLoad={(ref) =>
                                            (searchBoxRef.current = ref)
                                        }
                                        onPlacesChanged={onPlacesChanged}
                                    >
                                        <div className="input-group">
                                            <span className="input-group-text">
                                                <svg
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M16.6154 10.1538C16.6154 8.375 15.9832 6.85337 14.7188 5.58894C13.4543 4.32452 11.9327 3.69231 10.1538 3.69231C8.375 3.69231 6.85337 4.32452 5.58894 5.58894C4.32452 6.85337 3.69231 8.375 3.69231 10.1538C3.69231 11.9327 4.32452 13.4543 5.58894 14.7188C6.85337 15.9832 8.375 16.6154 10.1538 16.6154C11.9327 16.6154 13.4543 15.9832 14.7188 14.7188C15.9832 13.4543 16.6154 11.9327 16.6154 10.1538ZM24 22.1538C24 22.6538 23.8173 23.0865 23.4519 23.4519C23.0865 23.8173 22.6538 24 22.1538 24C21.6346 24 21.2019 23.8173 20.8558 23.4519L15.9087 18.5192C14.1875 19.7115 12.2692 20.3077 10.1538 20.3077C8.77885 20.3077 7.46394 20.0409 6.20913 19.5072C4.95433 18.9736 3.8726 18.2524 2.96394 17.3438C2.05529 16.4351 1.33413 15.3534 0.800481 14.0986C0.266827 12.8438 0 11.5288 0 10.1538C0 8.77885 0.266827 7.46394 0.800481 6.20913C1.33413 4.95433 2.05529 3.8726 2.96394 2.96394C3.8726 2.05529 4.95433 1.33413 6.20913 0.800481C7.46394 0.266827 8.77885 0 10.1538 0C11.5288 0 12.8438 0.266827 14.0986 0.800481C15.3534 1.33413 16.4351 2.05529 17.3438 2.96394C18.2524 3.8726 18.9736 4.95433 19.5072 6.20913C20.0409 7.46394 20.3077 8.77885 20.3077 10.1538C20.3077 12.2692 19.7115 14.1875 18.5192 15.9087L23.4663 20.8558C23.8221 21.2115 24 21.6442 24 22.1538Z"
                                                        fill="#00713D"
                                                    />
                                                </svg>
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)}
                                                placeholder={"Type and select your location"} />
                                        </div>
                                    </StandaloneSearchBox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default LocationDetection;

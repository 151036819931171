import React from "react";
import { lazy } from "react";
import Cart from "../pages/Cart/Cart";
import WishList from "../features/wishList/WishList";
import BannerDetail from "../pages/Dashboard/page/BannerDetail";
import SupportDetails from "../pages/Dashboard/page/Support/SupportDetails";
import ProductSubscription from "../pages/Dashboard/page/Subscription/ProductSubscription";
const Dashboard = lazy(() => import("../pages/Dashboard/page/Dashboard/Dashboard"))
const OrderHistory = lazy(() => import("../pages/Dashboard/page/MyOrder/OrderHistory"))
const OrderDetail = lazy(() => import("../pages/Dashboard/page/MyOrder/OrderDetail"))
const Home = lazy(() => import("../pages/Home/Home"))
const ProductList = lazy(() => import("../pages/Product/ProductList/ProductList"))
const ProductDetail = lazy(() => import("../pages/Product/ProductDetail/ProductDetail"))
const Address = lazy(() => import("../pages/Dashboard/page/Address/Address"));
const EditAddress = lazy(() => import  ("../pages/Dashboard/page/Address/EditAddress"));
const Wallet = lazy(() => import("../pages/Dashboard/page/Wallet/Wallet"));
const Subscription = lazy(() => import("../pages/Dashboard/page/Subscription/Subscription"));
const SubscriptionDetails = lazy(() => import("../pages/Dashboard/page/Subscription/SubscriptionDetails"));
const Support = lazy(() => import("../pages/Dashboard/page/Support/Support"));
const Checkout = lazy(() =>import("../pages/Checkout/Checkout"));
const PaymentSuccess = lazy(() =>import("../pages/PaymentSuccess/PaymentSuccess"));
const PaymentFaild = lazy(() =>import("../pages/PaymentFaild/PaymentFaild"));
const About = lazy(() => import("../pages/Dashboard/page/About"))
const RetunPolciy = lazy(() => import("../pages/Dashboard/page/ReturnPolciy"));
const TermsConditions = lazy(() => import("../pages/Dashboard/page/TermsConditions"));
const PrivacyPolicy = lazy(() => import("../pages/Dashboard/page/PrivacyPolicy"));


const routeConfig = [
    {
        path: "",
        element: <Home />,
        protected: false,
    },
    {
        path: "/product-list",
        element: <ProductList />,
        protected: false,
    },
    {
        path: "/product-detail",
        element: <ProductDetail />,
        protected: false,
    },
    {
        path : '/BannerDetail',
        element : <BannerDetail />,
        protected: false
    },
    {
        path: "/order-history",
        element: <OrderHistory />,
        protected: true,
    },
    {
        path: "/order-details",
        element: <OrderDetail />,
        protected: true,
    },
    {
        path: "/address",
        element: <Address />,
        protected: true,
    },
    {
        path: "/edit-address",
        element: <EditAddress />,
        protected: true,
    },
    {
        path: "/wallet",
        element: <Wallet />,
        protected: true,
    },
    {
        path: "/subscription",
        element: <Subscription />,
        protected: true,
    },
    {
        path: "/product-subscription",
        element: <ProductSubscription />,
        protected: true,
    },
    {
        path: "/subscription-details",
        element: <SubscriptionDetails />,
        protected: true,
    },
    {
        path: "/support",
        element: <Support />,
        protected: true,
    },
    {
        path: "/support-details",
        element: <SupportDetails />,
        protected: true,
    },
    
    {
        path: "/cart",
        element: <Cart />,
        protected: false,
    },
    {
        path: "/checkout",
        element: <Checkout />,
        protected: false,
    },
    {
        path: "/payment-success",
        element: <PaymentSuccess />,
        protected: false,
    },
    {
        path: "/payment-faild",
        element: <PaymentFaild />,
        protected: false,
    },
    {
        path: "/about-us",
        element: <About />,
        protected: false,
    },
    {
        path: "/return-policy",
        element: <RetunPolciy />,
        protected: false,
    },
    {
        path: "/terms-conditions",
        element: <TermsConditions />,
        protected: false,
    },
    {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
        protected: false,
    },
    {
        path: "/wish-list",
        element: <WishList />,
        protected: false,
    },
];

export default routeConfig;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const orderSummarySlice = createSlice({
    name: "orderSummary",
    initialState,
    reducers: {
        setOrderSummary: (state, action) => {
            return state = action.payload;
        },
    }
})

export const { setOrderSummary } = orderSummarySlice.actions;
export default orderSummarySlice.reducer;
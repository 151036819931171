import { useEffect, useState } from "react";
import { LoadingComponentScreen } from "../../../components/common/LoadingComponent/LoadingComponent";
import RelatedItems from "../../Product/ProductDetail/component/RelatedItems";
import { useSelector } from "react-redux";
import { ApiUrl, getRequest } from "../../../config/apiConfig";

export default function BannerDetail({ route }) {
    const [isLoading, setLoading] = useState(true)
    const [relatedProducts, setProducts] = useState([])
    const store = useSelector(s => s.store)
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const banner_id = params?.get("id");
        getBannerProductList(banner_id);
    }, [])
    const getBannerProductList = async (id) => {
        let storeId = store.store_id
        let url = ApiUrl.bannerProductList + id + "?store=" + storeId;
        let config = { url }
        getRequest(config)
            .then(data => {
                if (data.hasOwnProperty('result')) {
                    setProducts(data.result)
                } else {
                    setProducts([])
                }
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setLoading(false)
            })
    }
    return (
        <>
            <main>
                <RelatedItems
                    items={relatedProducts}
                    onClick={(d) => console.log(d)} />

                {isLoading && <LoadingComponentScreen />}
            </main>
        </>
    )
}
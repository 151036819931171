import React from 'react'
import { Link } from 'react-router-dom'

const OtpFormModal = () => {
    return (
        <>
            {/* modal OTP  */}
            <div className="modal fade" id="userModal000" tabIndex={-1} aria-labelledby="userModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-left">
                            <img className="img-fluid" src="assets/images/logo/logo.png" alt="Big Bonus Logo" />
                        </div>
                        <div className="modal-right">
                            <div className="close" data-bs-dismiss="modal" aria-label="Close">×</div>
                            <h4>Enter OTP<br />sent on mobile - 9812998128</h4>
                            <div id="inputs" className="inputs">
                                <input className="input" type="text" inputMode="numeric" maxLength={1} />
                                <input className="input" type="text" inputMode="numeric" maxLength={1} />
                                <input className="input" type="text" inputMode="numeric" maxLength={1} />
                                <input className="input" type="text" inputMode="numeric" maxLength={1} />
                            </div>
                            <button type="button" className="btn mt-3">Submit</button>
                            <p style={{ color: '#FFC530', fontSize: '0.8rem', lineHeight: '1.7' }}>Some thing wrong please Re-enter OTP</p>
                            <p className="text-modal">By Send OTP you agree to our <Link to="/terms-conditions" style={{ color: 'white' }}>Terms &amp;
                                Conditions</Link></p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default OtpFormModal
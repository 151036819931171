import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChangeAddressModal from "../../pages/Cart/ChangeAddressModal";
import CartEditModal from "../../pages/Cart/CartEditModal";
import CartdeleteModal from "../../pages/Cart/CartdeleteModal";
import WisheleteModal from "./WisheleteModal";
import { APIRequest, ApiUrl } from "../../config/apiConfig";
import { Link } from "react-router-dom";
import { useLocalStorage } from "../../Globals/helper";
import { LoadingComponentScreen } from "../../components/common/LoadingComponent/LoadingComponent";
import { getWishListData } from "./getWishList";
import { isValidDiscount, rs } from "../../utils/util";
import { AddToCartButton, } from "../../components/common/Quantity/Quantity";
import ConfirmationModal from "../../components/common/ConfirmationModal";
const WishList = () => {
    const dispatch = useDispatch();
    const wishList = useSelector((state) => state.wishList);
    const [token] = useLocalStorage("token");
    const { defaultStore, customer_id } = token || {};
    const [isLoading, setIsLoading] = useState(false);
    const [deleteAddressById, setDeleteAddressById] = useState({});
    const [isVisible, setVisible] = useState(false)
    const [itemToRemove, setItemToRemove] = useState(null);

    useEffect(() => {
        getData();
    }, []);
    const getData = () => {
        dispatch(getWishListData(customer_id))
    };

    const removeCartHandler = (item) => {
        let config = {
            method: "DELETE",
            url: `${ApiUrl?.deleteWishlistItems}/${item.wishlist_id}`,
        };
        APIRequest(
            config,
            (res) => {
                if (res?.status === 200) {
                    getData();
                }
            },
            (err) => {
                console.log(err, "remove item from wishlist");
            }
        );
        setVisible(false)
    };

    const showConfirmation = (item) => {
        setItemToRemove(item);  // Store the item for later removal
        setVisible(true);  // Show the confirmation modal
    };
    return (
        <>
            <section className="mb-lg-12 mb-3 mt-5">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-12">

                            <div className="mb-3 me-4">
                                {/* text */}
                                <h4 className="text-green fw-bold mb-0">
                                    {wishList?.length} Items in WishList
                                </h4>
                            </div>

                        </div>
                    </div>
                    <div>
                        {/* row */}
                        <div className="cart-container">
                            <div className="row product-list01">
                                {Object.values(
                                    wishList?.reduce((acc, dateObj) => {
                                        if (!acc[dateObj.category]) {
                                            acc[dateObj.category] = {
                                                category: dateObj.category,
                                                id: dateObj.product_id ?? dateObj.id * 100,
                                                plist: [],
                                            };
                                        }
                                        acc[dateObj.category].plist.push(dateObj);
                                        return acc;
                                    }, {})
                                )?.map((item, i) => {
                                    return (
                                        <div key={i} className="mt-3 cate-gr-text">
                                            <h4 className="align-items-center d-flex mb-3">
                                                {item.category}
                                            </h4>
                                            {item.plist.map((d, i) => (
                                                <div className="product-item col-md-12" key={i}>
                                                    <div className="product-item-img-bg">
                                                        <img style={{ maxWidth: "200px", }}
                                                            src={d?.product_image[0]}
                                                            alt={d?.product_image[0]}
                                                        />
                                                    </div>
                                                    <div className="product-details">
                                                        <p>{d?.product_name}</p>
                                                        <p>{d?.unit_qty}{d?.unit}</p>
                                                        <p className="text-red">{d?.stock<0&&'Sold Out'}</p>
                                                        <p className="mt-6">
                                                            <span className="original-price">
                                                                {isValidDiscount(parseInt(d.price) - parseInt(
                                                                    d.offer_price)) && rs + d.price}
                                                            </span>{" "}
                                                            <span className="discounted-price">
                                                                {rs + d?.offer_price}
                                                            </span>
                                                        </p>
                                                    </div>

                                                    <div className="d-flex justify-center align-items-center me-3">
                                                        <div>
                                                            <div>
                                                                <AddToCartButton data={d}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="d-flex ms-2 justify-content-center">
                                                            <div>
                                                                <Link
                                                                    className="text-black"
                                                                    onClick={() => showConfirmation(d)}>
                                                                    {" "}
                                                                    <svg
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M22.4986 4.5H1.51904C1.11319 4.5 0.761971 4.35547 0.465386 4.06641C0.1688 3.77734 0.0205078 3.42578 0.0205078 3.01172C0.0205078 2.59766 0.164898 2.24219 0.453679 1.94531C0.742459 1.64844 1.09758 1.5 1.51904 1.5H9.01173C9.01173 1.09375 9.15612 0.742188 9.4449 0.445312C9.73368 0.148438 10.0888 0 10.5103 0H13.5073C13.9288 0 14.2839 0.148438 14.5727 0.445312C14.8615 0.742188 15.0059 1.09375 15.0059 1.5H22.4986C22.9044 1.5 23.2556 1.64844 23.5522 1.94531C23.8488 2.24219 23.9971 2.59766 23.9971 3.01172C23.9971 3.42578 23.8488 3.77734 23.5522 4.06641C23.2556 4.35547 22.9044 4.5 22.4986 4.5ZM22.4986 21C22.4986 21.8281 22.2059 22.5352 21.6205 23.1211C21.0351 23.707 20.3288 24 19.5015 24H4.51612C3.6888 24 2.98246 23.707 2.39709 23.1211C1.81173 22.5352 1.51904 21.8281 1.51904 21V6H22.4986V21ZM7.51319 10.5C7.51319 10.0938 7.3649 9.74219 7.06831 9.44531C6.77173 9.14844 6.4166 9 6.00295 9C5.58929 9 5.23807 9.14844 4.94929 9.44531C4.66051 9.74219 4.51612 10.0938 4.51612 10.5V19.5C4.51612 19.9219 4.66051 20.2773 4.94929 20.5664C5.23807 20.8555 5.58929 21 6.00295 21C6.4166 21 6.77173 20.8555 7.06831 20.5664C7.3649 20.2773 7.51319 19.9219 7.51319 19.5V10.5ZM13.5073 10.5C13.5073 10.0938 13.359 9.74219 13.0625 9.44531C12.7659 9.14844 12.4108 9 11.9971 9C11.5834 9 11.2322 9.14844 10.9434 9.44531C10.6547 9.74219 10.5103 10.0938 10.5103 10.5V19.5C10.5103 19.9219 10.6547 20.2773 10.9434 20.5664C11.2322 20.8555 11.5834 21 11.9971 21C12.4108 21 12.7659 20.8555 13.0625 20.5664C13.359 20.2773 13.5073 19.9219 13.5073 19.5V10.5ZM19.5015 10.5C19.5015 10.0938 19.3532 9.74219 19.0566 9.44531C18.76 9.14844 18.4049 9 17.9912 9C17.5776 9 17.2264 9.14844 16.9376 9.44531C16.6488 9.74219 16.5044 10.0938 16.5044 10.5V19.5C16.5044 19.9219 16.6488 20.2773 16.9376 20.5664C17.2264 20.8555 17.5776 21 17.9912 21C18.4049 21 18.76 20.8555 19.0566 20.5664C19.3532 20.2773 19.5015 19.9219 19.5015 19.5V10.5Z"
                                                                            fill="#F20000"
                                                                        />
                                                                    </svg>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading && <LoadingComponentScreen />}
            </section>
            <WisheleteModal deleteAddressById={deleteAddressById} />
            {isVisible && <ConfirmationModal
                title={itemToRemove.product_name}
                message="Are you sure you want to remove from your Wishlist?"
                yes={() => removeCartHandler(itemToRemove)}
                setVisible={() => setVisible(false)}

            />}
        </>
    );
};

export default WishList;



import axios from "axios";
import { log, logError } from "../utils/util";

// export const BASEURL = 'https://store.milkanoagro.com/';
// export const paymentKey = "rzp_live_MjOUk06G3pMrce";
// export const RAZOR_PAY_KEY_SECRET = "TJtQKMZpOwWwx1h0BS7Jvrk5"

export const BASEURL = 'http://13.232.107.123:8080/';
export const paymentKey = "rzp_test_coWX4IgRN1KmIp";
export const RAZOR_PAY_KEY_SECRET = "Noh6zxWbJWmrDPHl4t6gx9ee"

export const googleAPI_Key = "AIzaSyDhTHeNJPONVmHWtY-WJAxZ7KENE5y6yFk";
export const APIBASEURL = `${BASEURL}api/v1/app`

export const ApiUrl = {
  login: `${APIBASEURL}/auth/login`,
  otpverify: `${APIBASEURL}/auth/otpverify`,
  getprofile: `${APIBASEURL}/customer/customerDetail/`,
  getbanner: `${APIBASEURL}/banner/getbanner`,
  getHomeLayout: `${APIBASEURL}/common/dashboardLayout`,
  dashboardProductList: `${APIBASEURL}/product/dashboardProductsByCategory/`,
  searchAndSaveLocation: `${APIBASEURL}/auth/searchAndSaveLocation`, //customer id, done
  dashboardOrganicDining: `${APIBASEURL}/product/dashboardOrganicDining`, //store id, done
  flashDeal: `${APIBASEURL}/common/getFlashDeal?store=`, //store id, done
  bannerProductList: `${APIBASEURL}/banner/productlist/`, //store id, done
  productById: `${APIBASEURL}/product/productById`, //store id, done
  getbysearch: `${APIBASEURL}/product/getbysearch`, //store id, done
  productsByCategory: `${APIBASEURL}/product/productsByCategory`, //store id, done
  categoryList: `${APIBASEURL}/product/categoryList`, //store id, done
  subcategoryList: `${APIBASEURL}/product/subcategoryList`,
  productsBySubCategory: `${APIBASEURL}/product/productsBySubCategory`, //store id, done
  getRandomProductBySubcat: `${APIBASEURL}/product/getRandomProductBySubcat`, //subcategory and product Id, done

  addtocart: `${APIBASEURL}/cart/addtocart`,
  getByCustomerId: `${APIBASEURL}/cart/getByCustomerId`, //customer id, done
  deleteCartItems: `${APIBASEURL}/cart/deleteCartItems`, //customer_id done, //store id, done

  addtowish: `${APIBASEURL}/wishlist/addtowishlist`,
  getWishListItemsId: `${APIBASEURL}/wishlist/getByCustomerId`, // customer id, done
  deleteWishlistItems: `${APIBASEURL}/wishlist/deleteWishlistItems`, // customer_id done, //store id, done

  subscriptionList: `${APIBASEURL}/subscription/getOrder/`,
  allSubscription: `${APIBASEURL}/subscription/productlist/all`,
  getSubscriptionDetails: `${APIBASEURL}/subscription/getOrderDetail`,
  // getSubscriptionDetails: `${APIBASEURL}/subscription/getOrderDetail`,
  subscriptionDetailsId: `${APIBASEURL}/subscription/subscriptiondetails/2/1`,  // customer id, done
  getSubscriptionOrderList: `${APIBASEURL}/subscription/getOrder/21/2`,   // customer_id done, //store id, done
  subscriptionCheckout: `${APIBASEURL}/subscription/checkout`,

  checkout: `${APIBASEURL}/cart/checkout`, //customer_id done, //store id, done
  getTransStatus: `${APIBASEURL}/cart/getTransStatus`,
  getDeliveryCharge: `${APIBASEURL}/common/getDeliveryCharge`,
  customerDefaultAddressEndPoint: `${APIBASEURL}/customer/customerAddress`, // customer id, done
  customerAllAddress: `${APIBASEURL}/customer/customerAddress`, // customer id, done
  customerUpdateAddress: `${APIBASEURL}/customer/updateAddress`, // customer id, done
  customerDeleteAddress: `${APIBASEURL}/customer/deleteAddress`, // customer id, done
  customersaveAddress: `${APIBASEURL}/customer/saveAddress`, //customer id, done
  getOrder: `${APIBASEURL}/order/getOrder`, //customer id, done
  getOrderDetail: `${APIBASEURL}/order/getOrderDetail`, //customer id, done
  getRazorpayTransStatus: `${APIBASEURL}/cart/getRazorpayTransStatus`, //customer id, done
  getRepayment: `${APIBASEURL}/cart/getRepayment`, //customer id, done
  getCMSContent: `${APIBASEURL}/common/getCms`,
  getDeliveryTime:`${APIBASEURL}/common/getDeliveryTime`,
  getFAQ:`${APIBASEURL}/common/getFaq`

};
export const AddressLookUp = "https://maps.googleapis.com/maps/api/geocode/json";
export const APIRequest = async (config = {}, onSuccess, onError) => {
  let userToken = JSON.parse(localStorage.getItem("token"));
  try {
    const response = await axios({
      method: config.method,
      url: config.url,
      data: config.body,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth": config?.token ?? userToken?.token,
      },
    });
    checkToken(response.data)
    onSuccess(response?.data);
  } catch (error) {
    checkToken(error?.response?.data)
    onError(error);
  }
};
export const getRequest = async (config = {}) => {
  let userToken = JSON.parse(localStorage.getItem("token"));
  return new Promise((resolve, reject) => {
    axios({
      method: config.method ?? 'GET',
      url: config.url,
      data: config.body,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth": config?.token ?? userToken?.token,
      },
    }).then(res => {
      resolve(res.data)
      checkToken(res.data)
    }).catch(err => {
      checkToken(err?.response?.data)
      reject(err)
    })
  })
};

export const APIRequestWithFile = async (config = {}, onSuccess, onError) => {
  let userToken = localStorage.getItem("token");
  if (!userToken) {
    onError(new Error("Token not found in localStorage"));
    return;
  }
  try {
    // Make the API request with axios
    const response = await axios({
      method: config.method,
      url: config.url,
      data: config.body,
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
        'x-auth': userToken?.token,
      },
    });
    // Call onSuccess callback with the response data
    onSuccess(response);
  } catch (error) {
    // Call onError callback with the error
    onError(error);
  }
};
export const checkToken = (res) => {
log(res)
  try {
    if (res?.message == 'Invalid token' || res?.message == 'Invalid token...') {
      localStorage.removeItem("token");
      window.location.href = "/"
    }
  } catch (error) {
    logError(error, 'auto logout error')
  }
}

export const getAddressObject = async address => {
  let obj = {};
  let element1 = address;
  var lat = element1.geometry.location.lat;
  var long = element1.geometry.location.lng;
  var addressDict = {
    latitude: lat,
    longitude: long,
  };
  await element1.address_components.forEach(element => {
    if (element.types.indexOf('locality') > -1) {
      addressDict['city'] = element.long_name;
    }
    if (element.types.indexOf('administrative_area_level_1') > -1) {
      addressDict['state'] = element.long_name;
    }
    if (element.types.indexOf('sublocality_level_1') > -1) {
      addressDict['addressline1'] = element.long_name;
    }
    if (element.types.indexOf('sublocality_level_2') > -1) {
      addressDict['addressline1Level2'] = element.long_name;
    }
    if (element.types.indexOf('sublocality_level_3') > -1) {
      addressDict['addressline1Level3'] = element.long_name;
    }
    if (element.types.indexOf('locality') > -1) {
      addressDict['addressline2'] = element.long_name;
    }
    if (element.types.indexOf('country') > -1) {
      addressDict['country'] = element.long_name;
    }
    if (element.types.indexOf('postal_code') > -1) {
      addressDict['zipCode'] = element.long_name;
    }
  });
  if (!addressDict.addressline1 || !addressDict.addressline2) {
    if (!addressDict.addressline1 && addressDict.addressline1Level2) {
      addressDict['addressline1'] = addressDict.addressline1Level2;
    } else if (!addressDict.addressline1 && addressDict.addressline1Level3) {
      addressDict['addressline1'] = addressDict.addressline1Level3;
    } else if (
      !addressDict.addressline1 &&
      !addressDict.addressline2 &&
      addressDict.city
    ) {
      addressDict['addressline1'] = addressDict.city;
      addressDict['addressline2'] = addressDict.city;
    } else if (
      !addressDict.addressline1 &&
      !addressDict.addressline2 &&
      addressDict.state
    ) {
      addressDict['addressline1'] = addressDict.state;
      addressDict['addressline2'] = addressDict.state;
    } else if (!addressDict.addressline1 && addressDict.addressline2) {
      addressDict['addressline1'] = addressDict.addressline2;
    } else if (addressDict.addressline1 && !addressDict.addressline2) {
      addressDict['addressline2'] = addressDict.addressline1;
    }
  }
  if (addressDict.country != 'India') {
    log('We offer delivery services across all regions in India only.')
    return '';
  }
  obj = {
    address: element1.formatted_address,
    address1: addressDict.addressline1,
    address2: addressDict.addressline2,
    addressDetails: addressDict,
    pincode: addressDict.zipCode,
    lat: lat,
    long: long,
  };

  return obj;
};
export const getAutoAddress = async (lat, long, preAddress, isFullAdd) => {
  let obj;
  let element1 = preAddress ?? {};
  let isFullAddress = isFullAdd ? true : false;
  await element1?.address_components?.forEach(element => {
    if (element.types.indexOf('postal_code') > -1) {
      isFullAddress = true;
    }
  });
  if (!isFullAddress) {
    let userLatLong = lat + "," + long
    let params = {
      latlng: userLatLong,
      key: googleAPI_Key,
    };
    let query = Object.keys(params)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
    let url = AddressLookUp + "?" + query;
    let res = await axios.get(url);
    let details = res.data.results;
    element1 = details[0];
  }
  obj = await getAddressObject(element1);
  if (preAddress) {
    obj.addressDetails.address = preAddress.formatted_address;
  }
  return obj;
};
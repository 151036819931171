import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const cartItemSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const existingItem = state.find(item => item.id === action.payload.id);
            if (existingItem) {
                existingItem.quantity += 1;
            } else {
                state.push({ ...action.payload, quantity: 1 });
            }
        },
        updateQuantity: (state, action) => {
            const item = state.find(item => item.id === action.payload.id);
            if (item) {
                if (action.payload.quantity <= 0) {
                    // Remove item if quantity is 0 or less
                    return state.filter(i => i.id !== item.id);
                } else {
                    item.quantity = action.payload.quantity;
                }
            }
        }
    }
})

export const { addToCart, removeFromCart, updateQuantity } = cartItemSlice.actions;
export default cartItemSlice.reducer;
import React from 'react'
import Slider from "react-slick";

const MySliders = ({ children, sliderOnject }) => {

    var settings = {
        dots: false,
        infinite: true,
        speed: sliderOnject?.speed,
        slidesToShow: sliderOnject?.slidesToShow,
        slidesToScroll: sliderOnject?.slidesToScroll,
        initialSlide: 0,
        prevArrow: (
            <button type="button" className="slick-prev">
                <i className="bi bi-chevron-left"></i>
            </button>
        ),
        nextArrow: (
            <button type="button" className="slick-next">
                <i className="bi bi-chevron-right"></i>
            </button>
        ),
        responsive: [
            
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className='slider-container'>
            <Slider {...settings}>
                {children}
            </Slider>
        </div>
    )
}

export default MySliders

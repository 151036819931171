export const rs='₹'
export const log=(a,b,c,d,e)=>{
  console.log('✅',a??'',b??'',c??'',d??'',e??'')
}
export const logError=(a,b,c,d,e)=>{
  console.log('❌',a??'',b??'',c??'',d??'',e??'')
}
export const isValidDiscount = s => {
  if (isNullOrEmpty(s)) {
    return false;
  }
  if (isNaN(s)) {
    return false;
  }
  if (s < 0) {
    return false
  }
  return true
}
export const isNullOrEmpty = s => {
    if (
      s == 'N/A' ||
      s === undefined ||
      s === null ||
      s === '' ||
      s === 'null' ||
      s === 'undefined' ||
      s == 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  export const isListNullOrEmpty = list => {
    if (list === undefined || list === null || list.length === 0) {
      return true;
    }
    return false;
  };
export const getCount = (item, cart) => {
  if (isNullOrEmpty(item) || isListNullOrEmpty(cart)) {
    return 0;
  }
  let itemID = item.product_id ?? item.id
  var count = 0;
  if (cart.filter(product => product.product_id == itemID).length > 0) {
    return parseInt(
      cart.filter(product => product.product_id == itemID)[0]?.qty,
    );
  }
  return count;
};

import { configureStore } from '@reduxjs/toolkit';
import cartItemReducer from '../features/cart/cartSlice';
import chooseCategory from '../pages/Product/ProductList/productSlice/productSlice';
import saveLocationSlice from '../features/location/saveLocationSlice';
import SearchSlice from '../components/layout/Header/SearchSlice/SearchSlice';
import getTotalCount from './reducers/getTotalCount';
import orderSummarySlice from './Slices/orderSummarySlice';
import storeSlice from './Slices/storeSlice'
import getWishList from '../features/wishList/getWishList'
import persistStore from 'redux-persist/es/persistStore';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage'
const persistConfig = {
  key: "store",
  storage,
};
const storePersistedReducer = persistReducer(persistConfig, storeSlice);
const store = configureStore({
  reducer: {
    cart: cartItemReducer,
    store:storePersistedReducer,
    productlist: chooseCategory,
    location : saveLocationSlice,
    search : SearchSlice,
    cartCount : getTotalCount,
    wishList : getWishList,
    orderSummary : orderSummarySlice,
  },
});
export default store;
export const persister = persistStore(store)

import React, { memo } from 'react'

const SelectDefaultDropdown = (props) => {
    const {
        optionData,
        onChange,
        defaultName
    } = props;

    return (
        <select className="form-select" onChange={onChange}>
            <option selected>{defaultName}</option>
            {
                optionData?.map((option, i) => (
                    <option value={option?.id}>{option?.option}</option>
                ))
            }
        </select>
    )
}

export default memo(SelectDefaultDropdown)
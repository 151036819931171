import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { APIRequest, ApiUrl } from '../../../config/apiConfig';
import { getCart } from '../../../store/reducers/getTotalCount';
import { useLocalStorage } from '../../../Globals/helper';
import { getCount, isNullOrEmpty, log } from '../../../utils/util';
import { toast } from "react-toastify";

export const AddToCartButton = ({ data, className }) => {
    const cart = useSelector(s => s.cartCount?.data)
    const store = useSelector(s => s.store)
    const [token] = useLocalStorage("token");
    const { defaultStore, customer_id } = token || {};
    const dispatch = useDispatch()
    const addtocartHandler = () => {
        let config = {
            method: 'POST',
            url: `${ApiUrl?.addtocart}`,
            body: {
                "customer_id": customer_id,
                "store_id": store ? store?.store_id : defaultStore,
                "order_details": [
                    {
                        product_id: data.product_id ?? data.id,
                        category_id: isNullOrEmpty(data.category_id) ? 2 : data.category_id,
                        product_quantity: 1,
                        subcategory_id: isNullOrEmpty(data.subcategory_id) ? 1 : data.subcategory_id
                    }
                ]
            }
        };
        console.log(config.body);

        APIRequest(
            config,
            res => dispatch(getCart(customer_id)),
            err => {
                console.log(err, 'add to cart');
            }
        );
    };

    return (
        getCount(data, cart) == 0 ? 
            <button type='button' disabled={data.stock < 1}
                className={`btn btn-primary ${className ? className : 'btn-grey btn-qlty-ct'}`} onClick={() => addtocartHandler()}>
            Add
        </button> :
            <Quantity productId={data} className={className ? className : ' btn-qlty-ct'} />
    )
}

export const Quantity = ({ productId, className }) => {
    const dispatch = useDispatch();
    const store = useSelector(s => s.store)
    const [token] = useLocalStorage("token");
    const cart = useSelector(s => s.cartCount?.data)
    const { defaultStore, customer_id } = token || {};
    const updateCartHandler = (newQuantity) => {
        let config = {
            method: 'POST',
            url: `${ApiUrl?.addtocart}`,
            body: {
                "customer_id": customer_id,
                "store_id": store ? store?.store_id : defaultStore,
                "order_details": [
                    {
                        product_id: productId.product_id ?? productId.id,
                        category_id: isNullOrEmpty(productId.category_id) ? 2 : productId.category_id,
                        product_quantity: newQuantity,
                        subcategory_id: isNullOrEmpty(productId.subcategory_id) ? 1 : productId.subcategory_id
                    }
                ]
            }
        };
        APIRequest(
            config,
            res => dispatch(getCart(customer_id)),
            err => {
                console.log(err, 'err ===============');
            }
        );
    };

    const incrementHandler = () => {
        log(productId, '.dd')
        const newQuantity = getCount(productId, cart) + 1;
        if(productId.stock < 1) return toast.error(`That's all we have in stock at the moment!`)
        updateCartHandler(newQuantity);
    };

    const decrementHandler = () => {
        const newQuantity = getCount(productId, cart) - 1;
        if (newQuantity > 0) {
            updateCartHandler(newQuantity);
        } else {
            let PID = productId.product_id ?? productId.id
            let result = cart?.find(cart => cart.product_id == PID)
            let config = {
                method: 'DELETE',
                url: `${ApiUrl?.deleteCartItems}/${result?.cartId}`,
            };
            APIRequest(
                config,
                res => dispatch(getCart(customer_id)),
                err => console.log(err, 'Remove cart handler')
            );
        }
    };


    return (
        productId.is_flash_deal ?
            <div className={`input-group input-spinner ${className}`}>
                <button type="button" className="btn btn-outline-success rounded btn-sm" style={{ width: '5.8rem' }} onClick={decrementHandler}>Remove</button>
                {/* <input type="number" value={getCount(productId, cart)} readOnly className="quantity-field form-control-sm form-input" /> */}
                <div style={{ color: 'green', fontSize: '11px' }} className='d-flex justify-content-center align-items-center mt-2'>
                    Flash Deals</div>
            </div> :
            <div className={`input-group input-spinner ${className}`}>
                <button type="button" className="button-minus btn btn-sm" onClick={decrementHandler}>-</button>
                <input type="number" value={getCount(productId, cart)} readOnly className="quantity-field form-control-sm form-input" />
                <button type="button" className="button-plus btn btn-sm" onClick={incrementHandler}>+</button>
            </div>
    );
}
import React, { useEffect, useState } from "react";
import AddNewAddressFormModal from "./AddNewAddressFormModal";
import { APIRequest, ApiUrl } from "../../config/apiConfig";
import { customerID } from "../../Globals/constent";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLocalStorage } from "../../Globals/helper";

const ChangeAddressModal = (props) => {
    const {
        setUpdateAddressById,
    } = props;
    const [token] = useLocalStorage("token");
    const { customer_id } = token || {};
    const [customerAllAddressResult, setCustomerAllAddressResult] = useState([]);

    const getCustomerAllAddressHandler = () => {
        let config = {
            method: "get",
            url: `${ApiUrl?.customerAllAddress}/${customer_id}?address=all`,
        };
        APIRequest(
            config,
            (res) => {
                if (res?.status === 200) {
                    setCustomerAllAddressResult(res?.result)
                }
            },
            (err) => {
                console.log(err, "err ===============");
            }
        );
    };

    useEffect(() => {
        getCustomerAllAddressHandler();
    }, []);

    const selectAddressHandler = (d) => {
        localStorage.setItem("userAddress", JSON.stringify(d))
        let listCloseButton = document.getElementById("listCloseButton");
        setTimeout(() => {
            if (listCloseButton) {
                listCloseButton.click();
            }
        }, 500)
    }

    const deleteCustomerAddressHandler = (d) => {
        let config = {
            method: "POST",
            url: `${ApiUrl?.customerDeleteAddress}`,
            body: {
                "customer_id": d?.customer_id,
                "id": d?.id
            }
        };
        APIRequest(
            config,
            (res) => {
                if (res?.success) {
                    toast.success(res?.message);
                    getCustomerAllAddressHandler();
                }
            },
            (err) => {
                console.log(err, "err ===============");
            }
        );
    };

    return (
        <>
            {/* ChangeAddressModal  */}
            <div className="modal fade" id="ChangeAddressModal" tabindex={-1} aria-labelledby="ChangeAddressModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content address-popup-modal">
                        <div class="close" data-bs-dismiss="modal" aria-label="Close" id="listCloseButton">
                            &times;
                        </div>
                        <div className="container mt-5" style={{ height: '500px', overflow: 'auto' }}>
                            <div class="row">
                                <div className="col-xl-12 col-12">
                                    {/* card */}
                                    <div className="card h-100 card-lg">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="table-responsive">
                                                    {/* Table */}
                                                    <table className="table mb-0 table-centered">
                                                        {/* tbody */}
                                                        <tbody>
                                                            <tr className="tr-bg">
                                                                <td>
                                                                    <div class="d-flex flex-column flex-md-row justify-content-between align-items-md-end">
                                                                        <div>
                                                                            <a href="#" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#AddNewAddressFormModal">
                                                                                <i class="bi bi-plus-lg"></i> Add a new address
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td />
                                                                <td />
                                                                <td />
                                                            </tr>

                                                            {
                                                                customerAllAddressResult?.map((d, i) => {
                                                                    return (
                                                                        <tr className="tr-bg" onClick={() => selectAddressHandler(d)}>
                                                                            <td>
                                                                                <Link to="#" className="text-inherit" >
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div>
                                                                                            <svg width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path
                                                                                                    d="M24.2197 12.4369L21.109 9.35458V1.99294H18.4422V6.71212L12.2197 0.546387L0.219727 12.4369L3.07648 12.3695V24.3909H21.236V12.4369H24.2197ZM12.2197 22.6716H6.29389V15.625H12.2197V22.6716ZM17.9769 19.6516H14.0829V15.5827H17.9769V19.6516Z"
                                                                                                    fill="#378547"
                                                                                                />
                                                                                            </svg>
                                                                                        </div>
                                                                                        <div className="ms-lg-4 mt-2 mt-lg-0">
                                                                                            <h5 className="mb-0 h6">{d?.label}</h5>
                                                                                            <span className="text-decoration-none text-muted">
                                                                                                {d?.address},
                                                                                                <br />
                                                                                                {d?.house_floor_no}, {d?.landmark}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Link>
                                                                            </td>
                                                                            <td />
                                                                            <td />
                                                                            <td className="text-end">
                                                                                <div className="d-inline-flex justify-content-between">
                                                                                    <div className=" mx-4" onClick={() => setUpdateAddressById(d)}>
                                                                                        <a className="text-black" data-bs-toggle="modal" data-bs-target="#CartEditModal">
                                                                                            <img src="assets/images/edit.png" alt className="icon-shape icon-xs" />
                                                                                        </a>
                                                                                    </div>
                                                                                    <div>
                                                                                        <a className="text-black" onClick={() => deleteCustomerAddressHandler(d)} data-bs-target="#CartdeleteModal">
                                                                                            {" "}
                                                                                            <img src="assets/images/delete.png" alt className="icon-shape icon-xs" />
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* AddNewAddressFormModal */}
            <AddNewAddressFormModal />
        </>
    );
};

export default ChangeAddressModal;

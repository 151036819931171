import React from 'react'
import { Link } from 'react-router-dom'
// import MySliders from '../../../../components/common/SlickSlider/MySliders'
import { isNullOrEmpty, rs } from '../../../../utils/util'
import { WishListIcon } from '../../../../features/wishList/WishListIcon'
import { AddToCartButton } from '../../../../components/common/Quantity/Quantity'

const RenderProductCard = ({ d, onClick }) => {
    return (

        <div className='item'>
            <div className="card card-product card-content">
                <div className="card-body">
                    <Link to={`/product-detail?productId=${d?.id}`} onClick={onClick} >
                        <div className="text-center position-relative">
                            <div
                                className=" d-flex justify-content-between position-absolute tag-dis mt-1 ms-1">
                                <span className="d-flex align-items-center position-relative">
                                    {!isNullOrEmpty(parseInt(d.discount)) && <span>
                                        <svg
                                            width="34"
                                            height="34"
                                            viewBox="0 0 48 48"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clip-path="url(#clip0_1948_532)">
                                                <path
                                                    d="M14 10C14 8.89583 13.6094 7.95312 12.8281 7.17188C12.0469 6.39062 11.1042 6 10 6C8.89583 6 7.95312 6.39062 7.17188 7.17188C6.39062 7.95312 6 8.89583 6 10C6 11.1042 6.39062 12.0469 7.17188 12.8281C7.95312 13.6094 8.89583 14 10 14C11.1042 14 12.0469 13.6094 12.8281 12.8281C13.6094 12.0469 14 11.1042 14 10ZM47.3438 28C47.3438 29.1042 46.9583 30.0417 46.1875 30.8125L30.8438 46.1875C30.0312 46.9583 29.0833 47.3438 28 47.3438C26.8958 47.3438 25.9583 46.9583 25.1875 46.1875L2.84375 23.8125C2.05208 23.0417 1.38021 21.9896 0.828125 20.6562C0.276042 19.3229 0 18.1042 0 17V4C0 2.91667 0.395833 1.97917 1.1875 1.1875C1.97917 0.395833 2.91667 0 4 0H17C18.1042 0 19.3229 0.276042 20.6562 0.828125C21.9896 1.38021 23.0521 2.05208 23.8438 2.84375L46.1875 25.1562C46.9583 25.9688 47.3438 26.9167 47.3438 28Z"
                                                    fill="#00713d"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1948_532">
                                                    <rect
                                                        width="48"
                                                        height="48"
                                                        fill="white"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <span className="dis-tag">
                                            {Math.round(parseInt(d?.discount)) + "%\n off"}
                                        </span>
                                    </span>}
                                </span>
                                <span className="text-danger">
                                    <WishListIcon
                                        data={d}
                                        className="btn-qlty-ct" />
                                </span>
                            </div>

                            <img src={d?.product_image[0]} alt={d?.name} className="img-fluid" />

                        </div>
                        <h2 className="fs-6 text-left">
                            <div className="text-inherit text-decoration-none">
                                {d?.product_name}
                            </div>
                        </h2>
                        <div className="text-small mb-0">
                            <div className="text-decoration-none text-muted">
                                <small>{d?.item_weight} gm</small>
                            </div>
                        </div>
                        <div className="text-small mb-0">
                            <div className="text-decoration-none text-muted">
                                <small className="text-red">{d.stock < 1 && 'Sold Out'}</small>
                            </div>
                        </div>
                    </Link>
                    <div className="d-flex justify-content-between align-items-center mt-3 pro-bttn">
                        <div>
                            <span className="text-decoration-line-through text-muted">
                                {!isNullOrEmpty(parseInt(d.discount)) && rs + d.price}
                            </span>
                            <br />
                            <span className="text-dark">
                                {rs + d?.offer_price}
                            </span>
                        </div>
                        <div>
                            <AddToCartButton data={d} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default RenderProductCard
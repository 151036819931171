import React, { useState } from "react";
// import "./ProductSubscription.css";

const ProductSubscription = () => {
  const [quantity, setQuantity] = useState(1);
  const [address, setAddress] = useState("101, Raj Nagar, Near Rajendra hospital, Delhi-110055");

  const incrementQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decrementQuantity = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  return (
    <div className="subscription-container">
      <div className="product-details">
        <img src="https://via.placeholder.com/150" alt="Fresh Coconut" className="product-image" />
        <h2>Fresh Coconut</h2>
        <p>Pack: 30 Pcs</p>
        <p className="mrp">MRP: <span>Rs.100</span> Rs.90</p>
        <span className="savings-badge">You saved Rs.10!</span>

        <div className="plan-selector">
          <h4>Select plan</h4>
          <div className="plan-buttons">
            <button>Daily</button>
            <button>Alternate</button>
            <button>Weekly</button>
          </div>
        </div>

        <div className="quantity-selector">
          <h4>Select quantity</h4>
          <button onClick={decrementQuantity}>-</button>
          <span>{quantity}</span>
          <button onClick={incrementQuantity}>+</button>
        </div>

        <div className="start-date">
          <h4>Select start date</h4>
          <input type="date" />
        </div>
      </div>

      <div className="billing-details">
        <h3>Billing details</h3>
        <p>Total MRP: <span>Rs.3000</span></p>
        <p>Discount offered: <span className="discount">You saved Rs.300</span></p>
        <p>Delivery charges: <span>Rs.0 (FREE)</span></p>
        <p>Handling charge: <span>Rs.3</span></p>
        <hr />
        <p>Total: <span className="total">Rs.2703</span></p>

        <h4>Delivery Address</h4>
        <p>{address}</p>
        <button className="edit-address">Edit</button>
        <button className="complete-address">Complete your address</button>

        <button className="pay-now">Pay Now</button>
      </div>
    </div>
  );
};

export default ProductSubscription;

import React from "react";

const VerifiedFormModal = () => {
    return (
        <>
            {/* VerifiedFormModal OTP  */}
            <div
                className="modal fade"
                id="VerifiedFormModal"
                tabIndex={-1}
                aria-labelledby="VerifiedFormModal"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body otp-verifeid">
                            <h5 className="otp-verifeid-h2">
                                Hey Virender ! Enjoy
                                <br /> shopping with Bigbonus{" "}
                            </h5>
                            <img
                                className="img-fluid"
                                src="assets/images/verified.png"
                                alt="Big Bonus Logo"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VerifiedFormModal;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiUrl, getRequest } from '../../config/apiConfig';
export const getCart = createAsyncThunk("cart/getCartData", async (customer_id) => {
    let config = {url: `${ApiUrl?.getByCustomerId}/${customer_id}`};
    const response = await getRequest(config)
    return response
})
const initialState = {};

const getTotalCount = createSlice({
    name: "cartCount",
    initialState,
    reducers: {
        setCart: (state, action) => {
            return state = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCart.fulfilled, (state, action) => {
        console.log(action.payload,'cart data red');
          return state=action.payload
        })
      },
})

export const { setCart } = getTotalCount.actions;
export default getTotalCount.reducer;

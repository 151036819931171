import React from 'react'
import { Outlet} from 'react-router-dom'
import Header from './Header/Header'
import Footer from './Footer/Footer'

const Layout = () => {
    // const token = localStorage.getItem("token");

    return (
        <div>
            <Header />
            <main className="main-content-wrapper">
                <Outlet />
            </main>
            {/* {
                token ? '' : (<Footer />)
            } */}

            <Footer />
        </div>
    );
}

export default Layout
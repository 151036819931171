import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const storeSlice = createSlice({
    name: "store",
    initialState,
    reducers: {
        setStore: (state, action) => {
            return state = action.payload;
        },
    }
})

export const { setStore } = storeSlice.actions;
export default storeSlice.reducer;
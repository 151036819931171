export const customerID = 52;

export const selectDropdown = [
  {
    id: 1,
    option: "Active"
  },
  {
    id: 2,
    option: "Deactive"
  },
  {
    id: 3,
    option: "Draft"
  },
]

export const subCategory = ["Fruits", "POT", "Seasonal", "Tender Coconut Melons", "Vegetables"]
export const categoryArray = [
  {
    "id": 1,
    "category": "Cold Drink",
    "imageSrc": "assets/images/category/cold-drink.png"
  },
  {
    "id": 2,
    "category": "Cold Drink & Juice",
    "imageSrc": "assets/images/category/cold-drink.png"
  },
  {
    "id": 3,
    "category": "Fruits & Vegetable",
    "imageSrc": "assets/images/category/fruits-cegetable-cate.png"
  },
  {
    "id": 4,
    "category": "Bakery & Biscuits",
    "imageSrc": "assets/images/category/bakery_biscuitcate.png"
  },
  {
    "id": 5,
    "category": "Milk & Dairy",
    "imageSrc": "assets/images/category/milk_dairy_cate.png"
  },
  {
    "id": 6,
    "category": "Sauces & Spreads",
    "imageSrc": "assets/images/category/sauces_cate.png"
  },
  {
    "id": 7,
    "category": "Dryfruits & Masale",
    "imageSrc": "assets/images/category/masala_cate.png"
  },
  {
    "id": 8,
    "category": "Oils & Ghee",
    "imageSrc": "assets/images/category/oil_ghee_cate.png"
  },
  {
    "id": 9,
    "category": "Tea & Coffee",
    "imageSrc": "assets/images/category/teacate.png"
  },
  {
    "id": 10,
    "category": "Personal Care",
    "imageSrc": "assets/images/category/personalcare_cate.png"
  },
  {
    "id": 11,
    "category": "Munchies",
    "imageSrc": "assets/images/category/munchiescate.png"
  }
]

export const homeCategoryArray = [
  {
    "link": "product-list",
    "img": "assets/images/category/drink.png",
    "categoryName": "Cold Drink & Juice"
  },
  {
    "link": "product-list",
    "img": "assets/images/category/bakery-and-biscuits.png",
    "categoryName": "Bakery & Biscuits"
  },
  {
    "link": "product-list",
    "img": "assets/images/category/milk-and-dairy.png",
    "categoryName": "Milk & Dairy"
  },
  {
    "link": "product-list",
    "img": "assets/images/category/sauces-and-spreads.png",
    "categoryName": "Sauces & Spreads"
  },
  {
    "link": "product-list",
    "img": "assets/images/category/oils-and-ghee.png",
    "categoryName": "Oils & Ghee"
  },
  {
    "link": "product-list",
    "img": "assets/images/category/tea-and-coffee.png",
    "categoryName": "Atta, Rice & Dal"
  },
  {
    "link": "product-list",
    "img": "assets/images/category/percenal-care.png",
    "categoryName": "Personal Care"
  },
  {
    "link": "product-list",
    "img": "assets/images/category/category-chicken-meat-fish.jpg",
    "categoryName": "Chicken, Meat & Fish"
  },
  {
    "link": "product-list",
    "img": "assets/images/category/category-cleaning-essentials.jpg",
    "categoryName": "Cleaning Essentials"
  },
  {
    "link": "product-list",
    "img": "assets/images/category/category-pet-care.jpg",
    "categoryName": "Pet Care"
  }
]

export const OrganicDiningCategoryArray = [
  {
    "link": "product-list",
    "img": "assets/images/products/paratha.png",
    "categoryName": "Paratha"
  },
  {
    "link": "product-list",
    "img": "assets/images/products/morning-snacks.png",
    "categoryName": "Morning Snacks"
  },
  {
    "link": "product-list",
    "img": "assets/images/products/tea-coffee.png",
    "categoryName": "Tea & Coffee"
  },
  {
    "link": "product-list",
    "img": "assets/images/products/juice.png",
    "categoryName": "Juices & Smoothies"
  },
  {
    "link": "product-list",
    "img": "assets/images/products/continental.png",
    "categoryName": "Continental"
  },
  {
    "link": "product-list",
    "img": "assets/images/products/chinese.png",
    "categoryName": "Chinese"
  },
  {
    "link": "product-list",
    "img": "assets/images/products/north-indian.png",
    "categoryName": "North Indian"
  },
  {
    "link": "product-list",
    "img": "assets/images/products/south-indian.png",
    "categoryName": "South Indian"
  }
]

export const DairyProductsCategoryArrat = [
  {
    "id": 1,
    "name": "Amul Milk FMC",
    "image": "assets/images/products/amul-milk.png",
    "link": "product-list",
    "weight": "500g",
    "old_price": 60,
    "new_price": 45
  },
  {
    "id": 2,
    "name": "Amul Milk FMC",
    "image": "assets/images/products/amul-milk.png",
    "link": "product-list",
    "weight": "500g",
    "old_price": 60,
    "new_price": 50
  },
  {
    "id": 3,
    "name": "Amul Milk FMC",
    "image": "assets/images/products/amul-milk.png",
    "link": "product-list",
    "weight": "500g",
    "old_price": 60,
    "new_price": 450
  },
  {
    "id": 4,
    "name": "Amul Milk FMC",
    "image": "assets/images/products/amul-milk.png",
    "link": "product-list",
    "weight": "500g",
    "old_price": 60,
    "new_price": 35
  },
  {
    "id": 5,
    "name": "Amul Milk FMC",
    "image": "assets/images/products/amul-milk.png",
    "link": "product-list",
    "weight": "500g",
    "old_price": 67,
    "new_price": 55
  },
  {
    "id": 6,
    "name": "Amul Milk FMC",
    "image": "assets/images/products/amul-milk.png",
    "link": "product-list",
    "weight": "500g",
    "old_price": 58,
    "new_price": 45
  },
  {
    "id": 7,
    "name": "Amul Milk FMC",
    "image": "assets/images/products/amul-milk.png",
    "link": "product-list",
    "weight": "500g",
    "old_price": 55,
    "new_price": 45
  },
  {
    "id": 8,
    "name": "Amul Milk FMC",
    "image": "assets/images/products/amul-milk.png",
    "link": "product-list",
    "weight": "500g",
    "old_price": 60,
    "new_price": 45
  },
  {
    "id": 9,
    "name": "Amul Milk FMC",
    "image": "assets/images/products/amul-milk.png",
    "link": "product-list",
    "weight": "500g",
    "old_price": 60,
    "new_price": 45
  },
  {
    "id": 10,
    "name": "Amul Milk FMC",
    "image": "assets/images/products/amul-milk.png",
    "link": "product-list",
    "weight": "500g",
    "old_price": 60,
    "new_price": 45
  }
]

export const productlistArray = [
  {
    "id": 1,
    "name": "Atta Rice & Dal",
    "image": "../assets/images/category/category-atta-rice-dal.jpg",
    "link": "product-detail",
    "old_price": "₹ 20",
    "new_price": "₹ 15",
    "discount": "-12%",
    "rating": {
      "stars": 5,
      "average": 5.0
    }
  },
  {
    "id": 2,
    "name": "Baby Care",
    "image": "../assets/images/category/category-baby-care.jpg",
    "link": "product-detail",
    "old_price": "₹ 24",
    "new_price": "₹ 18",
    "discount": "-25%",
    "rating": {
      "stars": 3.5,
      "average": 3.5
    }
  },
  {
    "id": 3,
    "name": "Tea Coffee & Drinks",
    "image": "../assets/images/category/category-tea-coffee-drinks.jpg",
    "link": "product-detail",
    "old_price": "₹ 24",
    "new_price": "₹ 18",
    "discount": "-25%",
    "rating": {
      "stars": 4.5,
      "average": 4.5
    }
  },
  {
    "id": 4,
    "name": "Cleaning Essentials",
    "image": "../assets/images/category/category-cleaning-essentials.jpg",
    "link": "product-detail",
    "old_price": "₹ 165",
    "new_price": "₹ 120",
    "discount": "-25%",
    "rating": {
      "stars": 4.5,
      "average": 4.5
    }
  },
  {
    "id": 5,
    "name": "Pet Cares",
    "image": "../assets/images/category/category-pet-care.jpg",
    "link": "product-detail",
    "old_price": "₹ 24",
    "new_price": "₹ 18",
    "discount": "-55%",
    "rating": {
      "stars": 3.5,
      "average": 3.5
    }
  },
  {
    "id": 6,
    "name": "Salted Instant Popcorn",
    "image": "../assets/images/category/category-chicken-meat-fish.jpg",
    "link": "product-detail",
    "old_price": "₹ 24",
    "new_price": "₹ 18",
    "discount": "-45%",
    "rating": {
      "stars": 4.5,
      "average": 4.5
    }
  },
  {
    "id": 7,
    "name": "Bakery & Biscuits",
    "image": "../assets/images/category/category-bakery-biscuits.jpg",
    "link": "product-detail",
    "old_price": "₹ 165",
    "new_price": "₹ 120",
    "discount": "-25%",
    "rating": {
      "stars": 4.5,
      "average": 4.5
    }
  },
  {
    "id": 8,
    "name": "Pet Cares",
    "image": "../assets/images/category/category-pet-care.jpg",
    "link": "product-detail",
    "old_price": "₹ 24",
    "new_price": "₹ 18",
    "discount": "-55%",
    "rating": {
      "stars": 3.5,
      "average": 3.5
    }
  },
  {
    "id": 9,
    "name": "Cleaning Essentials",
    "image": "../assets/images/category/category-cleaning-essentials.jpg",
    "link": "product-detail",
    "old_price": "₹ 165",
    "new_price": "₹ 120",
    "discount": "-25%",
    "rating": {
      "stars": 4.5,
      "average": 4.5
    }
  },
  {
    "id": 10,
    "name": "Salted Instant Popcorn",
    "image": "../assets/images/category/category-chicken-meat-fish.jpg",
    "link": "product-detail",
    "old_price": "₹ 24",
    "new_price": "₹ 18",
    "discount": "-45%",
    "rating": {
      "stars": 4.5,
      "average": 4.5
    }
  },
  {
    "id": 11,
    "name": "Salted Instant Popcorn",
    "image": "../assets/images/category/category-chicken-meat-fish.jpg",
    "link": "product-detail",
    "old_price": "₹ 24",
    "new_price": "₹ 18",
    "discount": "-45%",
    "rating": {
      "stars": 4.5,
      "average": 4.5
    }
  }
]

export const imageData = {
  "images": [
    {
      "className": "thumbnail active",
      "src": "assets/images/products/product-single-img-2.jpg"
    },
    {
      "className": "thumbnail",
      "src": "assets/images/products/product-single-img-3.jpg"
    },
    {
      "className": "thumbnail",
      "src": "assets/images/products/product-single-img-4.jpg"
    },
    {
      "className": "thumbnail",
      "src": "assets/images/products/product-single-img-4.jpg"
    },
    {
      "className": "thumbnail",
      "src": "assets/images/products/product-single-img-4.jpg"
    },
    {
      "className": "thumbnail",
      "src": "assets/images/products/product-single-img-4.jpg"
    }
  ]
};

export const relativeProductArray = [
  {
    "id": 1,
    "name": "Haldiram's Sev Bhujia",
    "category": "Snack & Munchies",
    "image": "assets/images/products/product-img-1.jpg",
    "link": "#!",
    "rating": 4.5,
    "reviews": 149,
    "price": 18,
    "old_price": 24,
    "badge": "Sale"
  },
  {
    "id": 2,
    "name": "NutriChoice Digestive",
    "category": "Bakery & Biscuits",
    "image": "assets/images/products/product-img-2.jpg",
    "link": "#!",
    "rating": 4.5,
    "reviews": 25,
    "price": 24,
    "old_price": null,
    "badge": null
  },
  {
    "id": 3,
    "name": "Cadbury 5 Star Chocolate",
    "category": "Bakery & Biscuits",
    "image": "assets/images/products/product-img-3.jpg",
    "link": "#!",
    "rating": 5.0,
    "reviews": 469,
    "price": 32,
    "old_price": 35,
    "badge": null
  },
  {
    "id": 4,
    "name": "Onion Flavour Potato",
    "category": "Snack & Munchies",
    "image": "assets/images/products/product-img-4.jpg",
    "link": "#!",
    "rating": 3.5,
    "reviews": 456,
    "price": 3,
    "old_price": 5,
    "badge": null
  },
  {
    "id": 5,
    "name": "Slurrp Millet Chocolate",
    "category": "Snack & Munchies",
    "image": "assets/images/products/product-img-9.jpg",
    "link": "#!",
    "rating": 4.5,
    "reviews": 67,
    "price": 6,
    "old_price": 10,
    "badge": null
  }
]

export const saveAddressButtonArray = [
  {
    "id": 1,
    "label": "Home",
    "icon": <svg
      width="13"
      height="15"
      style={{ marginRight: '3px' }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 11.8906L20.8893 8.80819V1.44656H18.2225V6.16573L12 0L0 11.8906L2.85676 11.8231V23.8445H21.0163V11.8906H24ZM12 22.1252H6.07416V15.0786H12V22.1252ZM17.7572 19.1052H13.8632V15.0363H17.7572V19.1052Z"
        fill="white"
      />
    </svg>
  },
  {
    "id": 2,
    "label": "Office",
    "icon": <svg
      width="13"
      height="15"
      style={{ marginRight: '3px' }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 22.3611V4.79166H16.5V0H3V22.3611H0V23.9583H10.5V19.1667H13.5V23.9583H24V22.3611H21ZM9 15.9722H6V12.7778H9V15.9722ZM9 11.1805H6V7.98611H9V11.1805ZM9 6.38888H6V3.19444H9V6.38888ZM13.5 15.9722H10.5V12.7778H13.5V15.9722ZM13.5 11.1805H10.5V7.98611H13.5V11.1805ZM13.5 6.38888H10.5V3.19444H13.5V6.38888ZM19.5 15.9722H16.5V12.7778H19.5V15.9722ZM19.5 11.1805H16.5V7.98611H19.5V11.1805Z"
        fill="#378547"
      />
    </svg>
  },
  {
    "id": 3,
    "label": "Other",
    "icon": <svg
      width="13"
      height="15"
      style={{ marginRight: '3px' }}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clip-path="url(#clip0_505_7197)"
        filter="url(#filter0_d_505_7197)"
      >
        <path
          d="M24.4643 17.9297C24.2143 18.2422 23.9643 18.5156 23.7143 18.75L15.9732 24L8.28571 18.75C8.03571 18.5156 7.78571 18.2422 7.53571 17.9297C6.42857 16.9609 5.5625 15.8359 4.9375 14.5547C4.3125 13.2734 4 11.9219 4 10.5C4 9.07812 4.31696 7.71875 4.95089 6.42188C5.58482 5.125 6.4375 4.00781 7.50893 3.07031C8.58036 2.13281 9.85714 1.38672 11.3393 0.832031C12.8214 0.277344 14.375 0 16 0C17.625 0 19.1786 0.277344 20.6607 0.832031C22.1429 1.38672 23.4196 2.13281 24.4911 3.07031C25.5625 4.00781 26.4152 5.125 27.0491 6.42188C27.683 7.71875 28 9.07812 28 10.5C28 11.9219 27.6875 13.2734 27.0625 14.5547C26.4375 15.8359 25.5714 16.9609 24.4643 17.9297ZM16 3C14.4464 3 13.0134 3.33594 11.7009 4.00781C10.3884 4.67969 9.34821 5.58984 8.58036 6.73828C7.8125 7.88672 7.42857 9.14062 7.42857 10.5C7.42857 11.8594 7.8125 13.1133 8.58036 14.2617C9.34821 15.4102 10.3884 16.3164 11.7009 16.9805C13.0134 17.6445 14.4464 17.9766 16 17.9766C17.5536 17.9766 18.9866 17.6445 20.2991 16.9805C21.6116 16.3164 22.6518 15.4102 23.4196 14.2617C24.1875 13.1133 24.5714 11.8594 24.5714 10.5C24.5714 9.14062 24.1875 7.88672 23.4196 6.73828C22.6518 5.58984 21.6116 4.67969 20.2991 4.00781C18.9866 3.33594 17.5536 3 16 3Z"
          fill="#378547"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_505_7197"
          x="0"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood
            flood-opacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_505_7197"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_505_7197"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_505_7197">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(4)"
          />
        </clipPath>
      </defs>
    </svg>
  }
]








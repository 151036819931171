import { createSlice } from '@reduxjs/toolkit';

const initialState = '';

const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        setSearchQuery: (state, action) => {
            const result = action.payload;
            return state = result;
        },
    }
})

export const { setSearchQuery } = searchSlice.actions;
export default searchSlice.reducer;
import React from 'react'
import { useSelector } from 'react-redux';
import { Quantity } from '../../../components/common/Quantity/Quantity';

const CartItemSideModal = () => {

    const cartItems = useSelector((state) => state.cart);

    return (
        <>
            <div className="offcanvas offcanvas-top" tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header border-bottom">
                    <div className="text-start">
                        <h6 id="offcanvasRightLabel" className="mb-0 fs-4">My Cart</h6>
                        <small>Delivery In 14 Minutes</small>
                    </div>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>
                <div className="offcanvas-body">
                    <div>
                        {/* alert */}
                        <div className="alert alert-danger p-2" role="alert">
                            3 Items selected 
                            <a href="#!" className="alert-link"> checkout now!</a>
                        </div>
                        <ul className="list-group list-group-flush">
                            {/* list group */}
                            {
                                cartItems?.map((d, i) => {
                                    return (
                                        <li className="list-group-item py-1 ps-0 border-top" key={i}>
                                            {/* row */}
                                            <div className="row align-items-center">
                                                <div className="col-6 col-md-6 col-lg-7">
                                                    <div className="d-flex">
                                                        <img src={d?.image} alt={d?.name} className="icon-shape" />
                                                        <div className="ms-3">
                                                            {/* title */}
                                                            <a href="pages/shop-single.html" className="text-inherit">
                                                                <h6 className="mb-0">{d?.name}</h6>
                                                            </a>
                                                            <span><small className="text-muted">{d?.weight}</small></span>
                                                            {/* text */}
                                                            <div className="mt-2 small lh-1">
                                                                <a href="#!" className="text-decoration-none text-inherit">
                                                                    <span className="me-1 align-text-bottom">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2 text-success">
                                                                            <polyline points="3 6 5 6 21 6" />
                                                                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                                                            </path>
                                                                            <line x1={10} y1={11} x2={10} y2={17} />
                                                                            <line x1={14} y1={11} x2={14} y2={17} />
                                                                        </svg>
                                                                    </span>
                                                                    <span className="text-muted">Remove</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* price */}
                                                <div className="col-2 text-lg-end text-start text-md-end col-md-2">
                                                    <span className="fw-bold"><i className="bi bi-currency-rupee" /> {d?.new_price}</span>
                                                </div>
                                                {/* input group */}
                                                <div className="col-4 col-md-3 col-lg-3">
                                                    {/* input */}   
                                                    <Quantity productId={d} />
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }

                        </ul>
                        {/* btn */}
                        <div className="d-flex justify-content-between mt-4">
                            <a href="#!" className="btn btn-primary"><i className="bi bi-currency-rupee" /> 1000.00 Total</a>
                            <a href="#!" className="btn btn-dark">Processed to Cart</a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CartItemSideModal
import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const saveLocationSlice = createSlice({
    name: "location",
    initialState,
    reducers: {
        saveLocation: (state, action) => {
            const data = action.payload
            return data;
        },
    }
})

export const { saveLocation } = saveLocationSlice.actions;
export default saveLocationSlice.reducer;